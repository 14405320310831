@import '../../assets/styles/partial.scss';

.subscriptions {

	&-body {
		@include responsive(margin, 100px, auto, 120px);
		@include responsive(grid-row-gap, 85px);

		&-first {
			@include responsive(grid-row-gap, 45px);
		}

		&-second {
			@include responsive(grid-row-gap, 65px);
		}
	}

	&-plans-table {

		&-captions {

			.subscriptions-plans-table-home & {
				@include responsive(width, 250px);
			}

			.subscriptions-plans-table-subscriptions & {
				@include responsive(width, 277px);
			}

			& > div {

				.subscriptions-plans-table-home & {
					background: var(--Black, #000);
					color: var(--White, #FFF);
					@include responsive(font-size, 18.0891px);
					@include responsive(line-height, 25px);
				}

				.subscriptions-plans-table-subscriptions & {
					@include responsive(font-size, 20px);
					@include responsive(line-height, 28px);
				}

				@include responsive(padding, 10.853px, 9.045px);
				border: none;
			}

			& .subscriptions-plans-table-cell-pricing,
			& .subscriptions-plans-table-cell-products,
			& .subscriptions-plans-table-cell-projects {
				border-bottom: 0.904px solid var(--Dark-grey, #A2A2A2);
			}
		}

		&-column {
			cursor: pointer;

			.subscriptions-plans-table-home & {
				@include responsive(width, 302.69px);
			}

			.subscriptions-plans-table-subscriptions & {
				@include responsive(width, 335.33px);
			}

			border-left: 0.904px solid var(--Dark-grey, #A2A2A2);

			&:last-child {
				border-right: 0.904px solid var(--Dark-grey, #A2A2A2);
			}
		}

		&-cell {

			&-head {

				.subscriptions-plans-table-home & {
					@include responsive(height, 158.279px);
					@include responsive(font-size, 21.707px);
				}

				.subscriptions-plans-table-subscriptions & {
					@include responsive(height, 175px);
					@include responsive(font-size, 24px);
					@include responsive(line-height, 33px);
				}

				background: var(--Black, #000);
				color: var(--White, #FFF);
				border-top: 0.904px solid var(--Dark-grey, #A2A2A2);
				@extend .font-helvetica;

				&-empty {
					background: transparent;
				}
			}

			&-pricing {

				.subscriptions-plans-table-home & {
					@include responsive(height, 164.611px);
					@include responsive(grid-row-gap, 4.52px);
				}

				.subscriptions-plans-table-subscriptions & {
					@include responsive(height, 183px);
					@include responsive(grid-row-gap, 5px);
				}

				background: #FFF;
				@include responsive(padding, 9.04px, 0);
				border-bottom: 0.904px solid var(--Dark-grey, #A2A2A2);
			}

			&-price {

				.subscriptions-plans-table-home & {
					@include responsive(grid-column-gap, 9.04px);
				}
				.subscriptions-plans-table-subscriptions & {
					@include responsive(grid-column-gap, 10px);
				}

				&-old {
					@extend .font-helvetica;
					@include responsive(font-size, 14.9px);

					& img {
						left: 0;
						bottom: 0;
					}
				}

				&-new {
					@extend .font-helvetica;

					.subscriptions-plans-table-home & {
						@include responsive(font-size, 19.898px);
					}
					
					.subscriptions-plans-table-subscriptions & {
						@include responsive(font-size, 22px);
						@include responsive(line-height, 30px);
					}
				}

				&-period {
					@extend .font-helvetica;

					.subscriptions-plans-table-home & {
						@include responsive(font-size, 18.089px);
					}
					
					.subscriptions-plans-table-subscriptions & {
						@include responsive(font-size, 20px);
						@include responsive(line-height, 28px);
					}
				}
			}

			&-products, &-projects {

				.subscriptions-plans-table-home & {
					@include responsive(height, 67.834px);
					@include responsive(font-size, 18.0891px);
					@include responsive(line-height, 25px);
				}
					
				.subscriptions-plans-table-subscriptions & {
					@include responsive(height, 75px);
					@include responsive(font-size, 20px);
					@include responsive(line-height, 28px);
				}

				background: #FFF;
				border-bottom: 0.904px solid var(--Dark-grey, #A2A2A2);
				@extend .font-helvetica;

				.subscriptions-plans-table-column-unlimited & {
					color: #C53939;
				}
			}

			&-description {

				.subscriptions-plans-table-home & {
					@include responsive(height, 132.05px);
					@include responsive(font-size, 14.471px);
				}
					
				.subscriptions-plans-table-subscriptions & {
					@include responsive(height, 146px);
					@include responsive(font-size, 16px);
					@include responsive(line-height, 22px);
				}

				@include responsive(padding, 10.853px, 9.045px);
				background: #FFF;
				border-bottom: 0.904px solid var(--Dark-grey, #A2A2A2);
				@extend .font-helvetica;
			}
		}
	}

	&-features {

		&-item {
			@include responsive(grid-row-gap, 25px);
			@include responsive(padding, 50px, 0);
			border-bottom: 1px solid #A2A2A2;

			&:first-child {
				border-top: 1px solid #A2A2A2;
			}

			&-head {
				@include responsive(grid-column-gap, 15px);
			}

			&-title {
				@include responsive(font-size, 28px);
				@include responsive(line-height, 39px);
			}

			&-icon {
				@include responsive(width, 40px);
				@include responsive(height, 40px);
			}

			&-body {
				@include responsive(width, 940px);
				@include responsive(grid-row-gap, 20px);
				@include responsive(font-size, 20px);
				@include responsive(line-height, 28px);
			}
		}
	}

	&-table {
		border-collapse: collapse;

		&-row {
			cursor: pointer;
		}

		&-cell {
			@include responsive(width, 256.8px);
			@include responsive(height, 85.5px);
			padding: 6px !important;
			font-family: 'Helvetica' !important;
			font-style: normal !important;
			font-weight: 400 !important;
			font-size: 20px !important;
			line-height: 28px !important;
			border: 1px solid #A2A2A2 !important;
			text-align: center !important;
		}
	}

	&-items-mobile {
		display: none;
	}
}

@media screen and (max-width: 576px) {
	.subscriptions {

		&-plans-table {
			@include responsive(width, 320px);
			flex-wrap: wrap;

			&-captions {
				display: none;
			}

			&-column {

				&-basic {
					width: 50%;
				}

				&-advanced {
					width: 50%;
					border-right: 0.904px solid var(--Dark-grey, #A2A2A2);
				}

				&-unlimited {
					width: 100%;
				}
			}

			&-cell {

				&-head {
					@include responsive(height, 60px);
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}

				&-pricing {
					@include responsive(height, 106px);
					@include responsive(grid-row-gap, 6px);
					@include responsive(padding, 10px, 0);
				}

				&-price {
					@include responsive(grid-column-gap, 5px);
					padding: 0;

					&-old {
						display: none;
					}

					&-new, &-period {
						@include responsive(font-size, 12px);
						@include responsive(line-height, 17px);
					}
				}

				&-products, &-projects {
					@include responsive(height, 33px);
					@include responsive(font-size, 12px);
					@include responsive(line-height, 17px);
				}

				&-description {
					display: none;
				}
			}
		}

		&-features {

			&-item {
				@include responsive(grid-row-gap, 25px);
				@include responsive(padding, 20px, 0);

				&-head {
					@include responsive(grid-column-gap, 15px);
				}

				&-title {
					@include responsive(font-size, 16px);
					@include responsive(line-height, 22px);
					text-align: center;
				}

				&-icon {
					@include responsive(width, 40px);
					@include responsive(height, 40px);
				}

				&-body {
					@include responsive(width, 320px);
					@include responsive(grid-row-gap, 0);
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}
			}
		}

		&-table {
			display: none !important;
		}

		&-items {

			&-mobile {
				display: flex;
				@include responsive(width, 320px);
				@include responsive(padding-bottom, 20px);
				overflow-x: scroll;
				scrollbar-width: thin;
				scrollbar-color: #A2A2A2 #DDDDDD;
				scrollbar-visibility: visible;
				&::-webkit-scrollbar {
					height: 6px;
					visibility: visible;
				}
				&::-webkit-scrollbar-thumb {
  					background-color: #A2A2A2;
				}
				&::-webkit-scrollbar-track {
  					background-color: #DDDDDD;
				}

				&-item {
					@include responsive(width, 320px);

					&:first-child {
						border-left: 1px solid #A2A2A2;
					}

					&-row {
						@include responsive(min-height, 65.7px);
						@include responsive(font-size, 12px);
						@include responsive(line-height, 17px);
						border-bottom: 1px solid #A2A2A2;

						&:first-child {
							border-top: 1px solid #A2A2A2;
						}

						& > div {
							@include responsive(width, 160px);
							@include responsive(padding, 10px);
							text-align: center;
							border-right: 1px solid #A2A2A2;
						}
					}

					&-name {
						@include responsive(height, 106px);


					}

					&-image {
						@include responsive(height, 69.27px);

						& img {
							width:100%;
					        height:100%;
					        object-position: center;
					        object-fit: cover;
						}
					}
				}
			}
		}
	}
}
