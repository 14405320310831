@import '../../assets/styles/partial.scss';

.footer {
	// @include responsive(height, 280px);
	@include responsive(padding-top, 40px);
	@include responsive(padding-bottom, 40px);
	background: var(--Black, #000);

	&-logo {
		@include responsive(width, 258px);
		// @include responsive(height, 140px);
		@include responsive(margin-bottom, 15px);
	}

	&-email, &-contacts {
		@include responsive(margin-bottom, 10px);
		color: var(--Dark-grey, #A2A2A2);
		text-align: center;
		@extend .font-16;
	}

	&-payment-logo {
		&-list {
			@include responsive(grid-column-gap, 10px);
		}
		&-item {
			@include responsive(width, 100px);

			& img {
				width: 100%;
			}
		}
	}

	&-docs {
		@include responsive(grid-column-gap, 10px);
		color: #7D7D7D;
		text-align: center;
		@extend .font-helvetica;
		@include responsive(font-size, 11px);
	}
}

@media screen and (max-width: 576px) {
	.footer {
		// @include responsive(height, 259px);
		@include responsive(padding-top, 40px);
		@include responsive(padding-bottom, 40px);

		&-logo {
			@include responsive(width, 199px);
			// @include responsive(height, 120px);
			@include responsive(margin-bottom, 15px);
		}

		&-email, &-contacts {
			@include responsive(margin-bottom, 10px);
			@include responsive(font-size, 11px);
			@include responsive(line-height, 15px);
		}

		&-payment-logo {
			&-list {
				flex-wrap: wrap;
				@include responsive(grid-column-gap, 10px);
				@include responsive(grid-row-gap, 10px);
			}
			&-item {
				@include responsive(width, 50px);

				& img {
					width: 100%;
				}
			}
		}

		&-docs {
			flex-direction: column;
			@include responsive(grid-row-gap, 10px);
			@include responsive(width, 300px);
			@include responsive(font-size, 8px);
			@include responsive(line-height, 11px);
		}
	}
}
