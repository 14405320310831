@import '../../assets/styles/partial.scss';

.auth {	

	&-form {
		@include responsive(margin, 100px, auto, 120px);
		@include responsive(grid-row-gap, 45px);
	}

	&-field {
		@include responsive(width, 413px);
		@include responsive(height, 80px);
		@include responsive(padding, 20px, 10px);
		border-bottom: 1px solid var(--Grey, #DDD); 

		& input {
			border: none;
			outline: none;
			color: var(--Black, #000);
			@extend .font-20;
		}

		&-error {
			border-bottom: 1px solid var(--Red, #C53939) !important;
		}
	}

	&-message {
		@include responsive(height, 22px);
		@extend .font-16;
	}

	&-google-button {
		// flex-direction: row-reverse;

		& > * {
			margin: 0 !important;
			padding: 0 !important;
		}

		& > div {
			background: url('../../assets/icons/google.png') !important;
			background-size: 100% !important;
			@include responsive(width, 26px);
			@include responsive(height, 26px);

			& svg {
				display: none;
			}
		}

		& > span {
			font-family: 'Helvetica' !important;
			font-style: normal !important;
			font-weight: 400 !important;
			@include responsive(font-size, 20px);
			@include responsive(line-height, 28px);
		}
	}
}

.register {

	&-form {
		@include responsive(width, 846px);
	}

	&-fields {

		&-custom {
			@include responsive(grid-column-gap, 20px);
		}

		&-gender {
			@include responsive(padding, 10px, 0px);
			@include responsive(grid-row-gap, 20px);

			&-title {
				color: var(--Black, #000);
				text-align: center;
				@extend .font-20;
				@include responsive(line-height, 28px);
			}

			&-dropdown {
				display: none;
				@extend .font-helvetica;
			}

			&-radio {
				@include responsive(height, 42px);
				border-bottom: 1px solid var(--Grey, #DDD); 

				&-item {
					@include responsive(width, 275px);

					& label {
						@include responsive(grid-column-gap, 10px);
						cursor: pointer;

						& input {
							display: none;
						}

						& img {
							@include responsive(width, 20.07px);
							@include responsive(height, 20.07px);
						}

						& span {
							color: var(--Black, #000);
							@extend .font-16;
						}
					}
				}
			}
		}

		&-accept {
			@include responsive(width, 492px);
			@include responsive(margin-top, -25px);
			@include responsive(margin-bottom, -25px);

			&-text {
				color: #000;
				@extend .font-14;
				cursor: pointer;
			}

			&-checkbox {
				-webkit-appearance: none;
  				appearance: none;
				@include responsive(width, 20px);
				@include responsive(height, 20px);
				border: 1px solid var(--Black, #000);
				outline: none;
				cursor: pointer;
				display: grid;
  				place-content: center;

				&::before {
				  	content: "";
					@include responsive(width, 12px);
					@include responsive(height, 12px);
				  	transform: scale(0);
				  	background-color: var(--Black, #000);
				}

				&:checked {

					&::before {
						transform: scale(1);
					}
				}
			}
		}
	}

	&-info {

		& br {
			display: none;
		}
	}
}

.login {

	&-form {
		@include responsive(width, 414px);

		&-body {
			@include responsive(grid-row-gap, 15px);
		}
	}

	&-fields {
		@include responsive(grid-row-gap, 10px);
	}

	&-or {
		color: var(--Black, #000);
		@extend .font-20;
		@include responsive(line-height, 28px);
	}

	&-bottom {
		@include responsive(grid-row-gap, 10px);
	}

	&-forgot {
		color: var(--Dark-grey, #A2A2A2);
		text-align: right;
		@extend .font-16;
		cursor: pointer;
	}
}

.account {

	&-forms {
		@include responsive(width, 952px);
		@include responsive(margin, 100px, auto, 120px);
		@include responsive(grid-row-gap, 85px);

		&-divider {
			width: 100%;
			border-bottom: 1px solid var(--Dark-grey, #A2A2A2);
		}
	}

	&-form {
		@include responsive(width, 724px);
		@include responsive(grid-row-gap, 45px);

		&-title {
			@include responsive(height, 33px);
			color: #000;
			@extend .font-24;
		}

		&-field {
			@include responsive(height, 80px);

			&-label {
				@include responsive(width, 291px);
				@include responsive(padding, 20px, 10px);
				border-bottom: 1px solid var(--Dark-grey, #A2A2A2);
				border-right: 1px solid var(--Dark-grey, #A2A2A2);
				color: var(--Black, #000);
				-webkit-text-stroke-width: 0.20000000298023224;
				-webkit-text-stroke-color: var(--Black, #000);
				@extend .font-20;
			}

			&-input {
				@include responsive(width, 433px);
				@include responsive(padding, 20px, 0px, 20px, 20px);
				border-bottom: 1px solid var(--Dark-grey, #A2A2A2);

				& input {
					border: none;
					outline: none;
					color: var(--Black, #000);
					@extend .font-20;
				}

				& img {
					cursor: pointer;
				} 
			}
		}
	}

	&-delete {
		@include responsive(margin-bottom, 120px);
	}
}

.profile {

	&-blocks {
		@include responsive(margin, 100px, auto, 120px);
		@include responsive(grid-row-gap, 85px);
	}

	&-data {

		&-left {
			@include responsive(grid-row-gap, 45px);
		}

		&-gallery {
			@include responsive(width, 522px);

			&-main {
				@include responsive(height, 450px);
				@include responsive(margin-bottom, 25px);

				& > img {
					width:100%;
			        height:100%;
			        object-position: center;
			        object-fit: cover;
				}

				&-actions {
					@include responsive(top, 10px);
					@include responsive(right, 10px);
					@include responsive(grid-row-gap, 10px);
				}
			}

			&-thumbs {
				@include responsive(grid-column-gap, 20px);
				@include responsive(grid-row-gap, 20px);
				@include responsive(margin-bottom, 20px);
			}

			&-thumb {
				@include responsive(width, 88px);
				@include responsive(height, 88px);
				cursor: pointer;

				& img {
					width:100%;
			        height:100%;
			        object-position: center;
			        object-fit: cover;
				}
			}

			&-download {
				@extend .font-16;
				cursor: pointer;
			}

			& .profile-error-message {
				@include responsive(bottom, -20px);
			}
		}

		&-buttons {
			@include responsive(grid-row-gap, 20px);
		}

		&-right {
			@include responsive(grid-row-gap, 20px);

			& .profile-error-message {
				@include responsive(top, -20px);
			}
		}

		&-field {
			@include responsive(height, 80px);

			&-label {
				@include responsive(width, 197px);
				@include responsive(padding, 20px, 10px);
				border-bottom: 1px solid var(--Dark-grey, #A2A2A2);
				border-right: 1px solid var(--Dark-grey, #A2A2A2);
				-webkit-text-stroke-width: 0.20000000298023224;
				-webkit-text-stroke-color: var(--Black, #000);
				@extend .font-20;
			}

			&-input {
				@include responsive(width, 433px);
				@include responsive(padding, 20px, 0px, 20px, 20px);
				border-bottom: 1px solid var(--Dark-grey, #A2A2A2);

				& input {
					border: none;
					outline: none;
					@extend .font-20;
				}

				& span {
					@extend .font-20;
				}
			}
				
			&-icon {
				@include responsive(width, 24px);
				@include responsive(height, 24px);
				cursor: pointer;
			}
		}

		&-socials {
			@include responsive(grid-column-gap, 15px);

			&-item {
				@include responsive(width, 49px);
				@include responsive(height, 49px);
			}
		}
	}

	&-products {
		@include responsive(grid-row-gap, 45px);

		&-title {
			@extend .font-24;
			@include responsive(line-height, 33px);
			text-align: center;
		}

		&-nav {
			@include responsive(width, 14px);
			@include responsive(height, 32px);
			@include responsive(top, 224px);
			cursor: pointer;

			&-prev {
				left: 0;
			}

			&-next {
				right: 0;
			}
		}

		&-slider {
			@include responsive(grid-column-gap, 20px);

			& .product-card {
				@include responsive(width, 270px);
			}
		}
	}

	&-description, 
	&-rules {
		@include responsive(grid-row-gap, 45px);

		&-title {
			@extend .font-24;
			@include responsive(line-height, 33px);
			text-align: center;
		}

		&-field {
			@include responsive(min-height, 195px);
			@include responsive(padding, 20px, 25px);
			border-top: 1px solid #A2A2A2;
			border-bottom: 1px solid #A2A2A2;

			&-textarea {
				color: var(--Black, #000);
				@extend .font-24;
				@include responsive(line-height, 33px);
				text-align: left;

				& textarea {
					border: none;
					outline: none;
					box-shadow: none;
					color: var(--Black, #000);
					@extend .font-24;
					@include responsive(line-height, 33px);
				}
			}

			&-icon {
				@include responsive(width, 24px);
				@include responsive(height, 24px);
				cursor: pointer;
			}
		}

		&-actions {
			@include responsive(grid-column-gap, 20px);
		}
	}
}

.store {

	&-body {
		@include responsive(margin, 100px, auto, 120px);
		@include responsive(grid-row-gap, 45px);
	}

	&-title {
		@include responsive(height, 33px);
		color: #000;
		@extend .font-24;
	}

	&-buttons {
		@include responsive(grid-column-gap, 20px);
	}

	&-table {
		border-collapse: collapse;

		&-row {
			cursor: pointer;
		}

		&-cell {
			@include responsive(height, 140px);
			padding: 6px !important;
			font-family: 'Helvetica' !important;
			font-style: normal !important;
			font-weight: 400 !important;
			font-size: 20px !important;
			line-height: 28px !important;
			border: 1px solid #A2A2A2 !important;
			text-align: center !important;

			&-num {
				@include responsive(width, 72px);
			}

			&-image {
				@include responsive(width, 224px);

				& img {					
					width: 100%;
					height: 100%;
			        object-position: center;
			        object-fit: cover;
				}
			}

			&-amount {
				@include responsive(width, 128px);
			}

			&-contracts {
				@include responsive(width, 128px);
			}			
		}
	}
}

.favorites {
	&-body {
		@include responsive(margin, 100px, auto, 150px);
		@include responsive(grid-row-gap, 85px);
	}

	&-lists {
		@include responsive(width, 267px);
	}

	&-list {
		@include responsive(height, 57px);
		@include responsive(padding, 10px, 0px);
		border-bottom: 1px solid #000;

		& > div {
			cursor: pointer;
		}

		&-title {
			@include responsive(grid-column-gap, 17px);

			& span {
				@extend .font-helvetica;
				@include responsive(font-size, 22px);
				@include responsive(line-height, 30px);
			}

			& img {
				@include responsive(width, 4px);
				@include responsive(height, 9px);
			}
		}

		&-remove {
			@include responsive(width, 24px);
			@include responsive(height, 24px);
		}

		&-delete {
			display: none;
			@extend .font-helvetica;
		}

		&-all {
			@include responsive(margin-bottom, 10px);
			@extend .font-helvetica;
			@include responsive(font-size, 22px);
			@include responsive(line-height, 30px);
			border-bottom: 3px solid #000;
			cursor: pointer;
		}
	}

}

@media screen and (max-width: 1440px) and (min-width: 577px) {
	.store-table-cell {
		padding: calc(6 * 100vw / 1440) !important;
		font-size: calc(20 * 100vw / 1440) !important;
		line-height: calc(28 * 100vw / 1440) !important;
	}
}

@media screen and (max-width: 576px) {
	.auth {	

		&-form {
			width: 100%;
			@include responsive(margin, 25px, auto, 100px);
			@include responsive(grid-row-gap, 20px);
		}

		&-field {
			width: 100%;
		}

		&-message {
			@include responsive(height, 17px);
			@include responsive(font-size, 12px);
			@include responsive(line-height, 17px);
		}

		&-google-button {

			& > div {
				@include responsive(width, 17px);
				@include responsive(height, 17px);
			}

			& > span {
				@include responsive(font-size, 12px);
				@include responsive(line-height, 17px);
			}
		}
	}

	.register {

		&-fields {
			@include responsive(width, 228px);
			@include responsive(grid-row-gap, 15px);

			& .auth-field {
				@include responsive(width, 228px);
				@include responsive(height, 33px);
				@include responsive(padding, 8px);

				& input {
					@include responsive(font-size, 12px);
					@include responsive(line-height, 17px);
				}
			}

			&-custom {
				flex-direction: column;
				@include responsive(grid-row-gap, 15px);
			}

			&-person {
				flex-direction: row;
				@include responsive(grid-column-gap, 15px);

				& .auth-field {
					@include responsive(width, 106.5px);
				}
			}

			&-gender {
				padding: 0;
				@include responsive(grid-row-gap, 20px);

				&-title {
					display: none;
				}

				&-dropdown {
					display: flex;
					@include responsive(grid-column-gap, 20px);
					@include responsive(font-size, 12px);
					@include responsive(line-height, 17px);
					cursor: pointer;
				}

				&-radio {
					display: none;
					flex-direction: column;
					@include responsive(width, 153px);
					@include responsive(height, 103px);
					@include responsive(grid-row-gap, 2px);
					position: absolute;
					@include responsive(top, 33px);
					left: calc((75 * 100vw / 360) / 2);
					background: #FFFFFF;
					border: 2px solid #000000;

					&-opened {
						display: flex;
					}

					&-item {
						width: 100%;

						& label {

							& img {
								display: none;
							}

							& span {
								@include responsive(font-size, 14px);
								@include responsive(line-height, 29px);
							}
						}
					}
				}
			}

			&-accept {
				width: 100%;
				margin: 0;

				&-text {
					@include responsive(font-size, 9px);
					@include responsive(line-height, 12px);

					& span {						
						@include responsive(font-size, 12px);
						@include responsive(line-height, 17px);
					}
				}

				&-checkbox {
					@include responsive(width, 20px);
					@include responsive(height, 20px);

					&::before {
						@include responsive(width, 12px);
						@include responsive(height, 12px);
					}
				}
			}
		}

		&-info {
			
			& br {
				display: block;
			}
		}
	}


	.login {

		&-form {
			width: 100%;

			&-body {
				width: 100%;
				@include responsive(grid-row-gap, 20px);
			}
		}

		&-fields {
			width: 100%;
			@include responsive(grid-row-gap, 15px);

			& .auth-field {
				@include responsive(height, 49px);
				@include responsive(padding, 15px);

				& input {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}
			}
		}

		&-or, &-forgot  {
			@include responsive(font-size, 12px);
			@include responsive(line-height, 17px);
		}

		&-bottom {
			@include responsive(grid-row-gap, 20px);
		}
	}


	.account {

		&-forms {
			width: 100%;
			@include responsive(margin, 25px, auto, 60px);
			@include responsive(grid-row-gap, 45px);

			&-divider {
				display: none;
			}
		}

		&-form {
			width: 100%;
			@include responsive(grid-row-gap, 20px);

			&-title {
				@include responsive(height, 28px);
				@include responsive(font-size, 20px);
				@include responsive(line-height, 28px);
			}

			&-field {
				height: auto;

				&-label {
					@include responsive(width, 160px);
					@include responsive(padding, 15px, 10px);
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}

				&-input {
					@include responsive(width, 160px);
					@include responsive(padding, 15px, 10px);

					& input {
						@include responsive(font-size, 14px);
						@include responsive(line-height, 19px);
					}

					& img {
						display: none;
					} 
				}
			}
		}

		&-delete {
			@include responsive(margin-bottom, 100px);
		}
	}

	.profile {

		&-blocks {
			@include responsive(margin, 25px, auto, 100px);
			@include responsive(grid-row-gap, 45px);
		}

		&-data {
			flex-direction: column;
			@include responsive(grid-row-gap, 45px);

			&-left {
				@include responsive(grid-row-gap, 20px);
			}

			&-gallery {
				width: 100%;

				&-main {
					@include responsive(height, 212px);
					@include responsive(margin-bottom, 10px);

					&-actions {
						@include responsive(top, 10px);
						@include responsive(right, 10px);
						@include responsive(grid-row-gap, 10px);
					}
				}

				&-thumbs {
					@include responsive(grid-column-gap, 15px);
					@include responsive(grid-row-gap, 15px);
					@include responsive(margin-bottom, 20px);
				}

				&-thumb {
					@include responsive(width, 68.75px);
					@include responsive(height, 55px);
				}

				&-download {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}

				& .profile-error-message {
					@include responsive(bottom, -20px);
				}
			}

			&-buttons {
				@include responsive(grid-row-gap, 20px);
			}

			&-right {
				@include responsive(grid-row-gap, 20px);

				& .profile-error-message {
					@include responsive(top, -20px);
				}
			}

			&-field {
				@include responsive(height, 49px);

				&-label {
					@include responsive(width, 160px);
					@include responsive(padding, 15px, 10px);
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}

				&-input {
					@include responsive(width, 160px);
					@include responsive(padding, 15px, 10px);

					& input, & span {
						@include responsive(font-size, 14px);
						@include responsive(line-height, 19px);
					}
				}
					
				&-icon {
					@include responsive(width, 17px);
					@include responsive(height, 17px);
				}
			}

			&-socials {
				@include responsive(grid-column-gap, 12px);

				&-item {
					@include responsive(width, 33px);
					@include responsive(height, 33px);
				}
			}
		}

		&-products {
			@include responsive(grid-row-gap, 20px);

			&-title {
				@include responsive(font-size, 16px);
				@include responsive(line-height, 22px);
			}

			&-nav {
				display: none;
			}

			&-slider {
				@include responsive(grid-column-gap, 15px);
				overflow-x: scroll; 

				& .product-card {
					@include responsive(width, 153px);

					&:first-child, &:last-child {
						display: none;
					}
				}
			}

			&-add {
				width: 100%;
			}
		}

		&-description, 
		&-rules {
			@include responsive(grid-row-gap, 20px);

			&-title {
				@include responsive(font-size, 20px);
				@include responsive(line-height, 28px);
			}

			&-field {
				@include responsive(min-height, 125px);
				@include responsive(padding, 15px, 0);
				border-top: 1px solid #A2A2A2;
				border-bottom: 1px solid #A2A2A2;

				&-textarea {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
					text-align: center;

					& textarea {
						@include responsive(font-size, 14px);
						@include responsive(line-height, 19px);
					}
				}

				&-icon {
					display: none;
				}
			}

			&-actions {
				@include responsive(grid-column-gap, 20px);
			}
		}
	}

	.store {

		&-body {
			@include responsive(margin, 25px, auto, 100px);
			@include responsive(grid-row-gap, 20px);
		}

		&-title {
			@include responsive(font-size, 20px);
			@include responsive(line-height, 28px);
		}

		&-buttons {
			flex-direction: column-reverse;
			@include responsive(grid-row-gap, 10px);

			& button, & .btn {
				width: 100%;
			}
		}

		&-table {

			&-cell {
				@include responsive(height, 140px);
				padding: 2px !important;
				font-size: calc(11 * 100vw / 360) !important;
				line-height: calc(15 * 100vw / 360) !important;

				&-num {
					@include responsive(width, 72px);
				}

				&-image {
					@include responsive(width, 224px);

					& img {					
						width: 100%;
						height: 100%;
				        object-position: center;
				        object-fit: cover;
					}
				}

				&-amount {
					@include responsive(width, 128px);
				}

				&-contracts {
					@include responsive(width, 128px);
				}			
			}
		}
	}

	.favorites {
		&-body {
			@include responsive(margin, 25px, auto, 120px);
			@include responsive(grid-row-gap, 20px);
		}

		&-top {
			flex-wrap: wrap;
			position: relative;

			& .catalog-top-actions {
				flex-direction: column-reverse;
				@include responsive(grid-row-gap, 20px);
			}

			& .catalog-top-actions-sort {
				display: flex;
				margin: 0;
				@include responsive(height, 31px);
			}
		}

		&-create {
			position: absolute;
			left: 0;
			bottom: 0;
		}

		&-content {
			flex-direction: column;
			@include responsive(grid-row-gap, 45px);
		}

		&-lists {
			// @include responsive(width, 267px);
			width: auto;
			flex-direction: row;
			@include responsive(grid-column-gap, 30px);
		}

		&-list {
			width: auto !important;
			@include responsive(height, 30px);
			@include responsive(padding, 0px, 0px, 10px);
			border-bottom: none;

			&-all {
				@include responsive(font-size, 14px);
				@include responsive(line-height, 19px);
				@include responsive(padding, 0px, 0px, 10px);
				margin: 0;
				border-bottom: none;
			}

			&-active {
				border-bottom: 2px solid #000;
			}

			&-title {

				& span {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}

				& img {
					display: none;
				}
			}

			&-remove {
				display: none;
			}

			&-delete {
				display: flex;
				@include responsive(margin-top, -35px);
				@include responsive(font-size, 12px);
				@include responsive(line-height, 17px);
				color: #A2A2A2;
				cursor: pointer;
			}
		}

	}

}
