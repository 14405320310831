@import '../../assets/styles/partial.scss';

.header {
	@include responsive(padding, 20px, 0, 25px);
	@include responsive(grid-row-gap, 25px);
	border-bottom: 2px solid var(--Dark-grey, #A2A2A2); 

	&-top {
		@include responsive(height, 73px);
	}

	&-logo {
		// @include responsive(width, 343px);
		// @include responsive(height, 73px);
	}

	&-infobar {
		@include responsive(grid-column-gap, 25px);
	}

	&-buttons {
		@include responsive(grid-column-gap, 20px);

		&-project {
			@include responsive(grid-column-gap, 20px);

			&-active {
				@extend .font-16;
			}

			&-none {
				@extend .font-20;
				color: var(--Dark-grey, #A2A2A2);
			}

			&-menu {
				@include responsive(top, 45px);
				right: 0;
				@include responsive(padding, 6px, 15px);
				@include responsive(grid-row-gap, 2px);
				border: 3px solid var(--Black, #000);
				background: var(--White, #FFF);
				z-index: 2;

				&-item {
					@include responsive(padding, 13px, 10px);
					color: var(--Black, #000);
					@extend .font-16;
					cursor: pointer;
				}
			}
		}
	}

	&-actions {
		@include responsive(width, 192px);

		& > div {
			cursor: pointer;
		}

		&-language {
			@extend .font-16;
			@include responsive(line-height, 22px);
		}

		&-notifications {
			@include responsive(width, 32px);
			@include responsive(height, 39px);

			&-marker {
				@include responsive(width, 16px);
				@include responsive(height, 16px);
				top: 0;
				right: 0;
				border-radius: 100%;
				background-color: #DA932A;
			}

			&-menu {
				@include responsive(top, 52px);
				right: 0;
				@include responsive(width, 413px);
				@include responsive(max-height, 228px);
				overflow-y: scroll;
				@include responsive(padding, 15px);
				@include responsive(grid-row-gap, 2px);
				border: 3px solid var(--Black, #000);
				background: var(--White, #FFF);
				z-index: 2;

				&-item {
					@include responsive(padding, 13px, 20px, 13px, 10px);

					&-content {
						color: var(--Black, #000);
						@extend .font-16;

						.old & {
							color: #A2A2A2 !important;
						}
					}

					&-marker {
						@include responsive(width, 16px);
						@include responsive(height, 16px);
						border-radius: 100%;
						background-color: #DA932A;
					}
				}
			}
		}

		&-account {
			&-avatar {
				@include responsive(width, 31px);
				@include responsive(height, 30px);
				border-radius: 100%;
				overflow: hidden;

				& img {				
					width: 100%;
					height: 100%;
			        object-position: center;
			        object-fit: cover;
				}
			}

			&-name {
				@extend .font-16;
				@include responsive(line-height, 22px);
			}

			&-menu {
				@include responsive(top, 52px);
				right: 0;
				@include responsive(padding, 6px, 15px);
				@include responsive(grid-row-gap, 2px);
				border: 3px solid var(--Black, #000);
				background: var(--White, #FFF);
				z-index: 2;

				&-item {
					@include responsive(padding, 13px, 10px);
					color: var(--Black, #000);
					@extend .font-16;
				}
			}
		}

		&-menu {
			display: none;

			&-dropdown {
				display: none;
			}
		}
	}

	&-navbar {
		@include responsive(padding, 8px, 0px);

		&-item {
			@include responsive(width, 256.8px);
			color: var(--Black, #000);
			text-align: center;
			@extend .font-16;
			cursor: pointer;
			border-left: 1px solid var(--Dark-grey, #A2A2A2);

			&:last-child {
				border-right: 1px solid var(--Dark-grey, #A2A2A2);
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.header {
		@include responsive(padding, 10px, 0, 12px);

		&-top {
			align-items: center;
			@include responsive(height, 33px);
		}

		&-logo {
			// width: auto;
			// @include responsive(height, 33px);
		}

		&-buttons {
			display: none;
		}

		&-actions {
			@include responsive(width, 98px);

			&-language {
				display: none;
			}

			&-notifications {
				@include responsive(width, 18px);
				@include responsive(height, 23px);
			}

			&-account {
				&-avatar {
					@include responsive(width, 25px);
					@include responsive(height, 24px);
				}

				&-name {
					@include responsive(font-size, 12px);
					@include responsive(line-height, 17px);
				}

				&-menu {
					@include responsive(top, 33px);
					@include responsive(padding, 6px, 5px);
					@include responsive(grid-row-gap, 2px);

					&-item {
						@include responsive(padding, 5px, 22.5px);
						@include responsive(font-size, 12px);
						@include responsive(line-height, 17px);
					}
				}
			}

			&-menu {
				display: block;
				@include responsive(width, 25px);
				@include responsive(height, 14px);

				&-dropdown {
					display: flex;
				}
			}
		}

		&-navbar {
			display: none;
		}
	}
}
