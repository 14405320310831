@import '../../assets/styles/partial.scss';

.chat {

	&-top {
		@include responsive(grid-row-gap, 100px);
		@include responsive(margin-bottom, 45px);
	}

	&-select {
		@include responsive(height, 50px);
		border: 1px solid #A2A2A2;

		&-item {
			width: 50%;

			&:first-child {
				border-right: 1px solid #A2A2A2;
			}

			& > div {
				width: 50%;
			}

			&-label {
				@include responsive(padding, 10px);
				border-right: 1px solid #A2A2A2;
				@extend .font-20;
				@include responsive(line-height, 28px);
			}

			&-dropdown {

				&-select {
					@include responsive(padding, 0, 20px);
					cursor: pointer;

					& span {
						@extend .font-20;
						@include responsive(line-height, 28px);
					}

					& img {
						@include responsive(width, 16px);
						@include responsive(height, 6px);
					}
				}

				&-menu {
					@include responsive(max-height, 200px);
					overflow-y: scroll; 
					@include responsive(grid-row-gap, 10px);
					@include responsive(padding, 10px, 20px);
					background: #FFF;
					border: 1px solid #A2A2A2;
					z-index: 3;

					&-item {
						@extend .font-16;
						@include responsive(line-height, 22px);
						cursor: pointer;

						&-selected {
							font-weight: 700;
						}
					}
				}
			}
		}
	}

	&-title {
		@include responsive(height, 65px);
		background: #000;
		@extend .font-24;
		@include responsive(line-height, 33px);
		color: #FFFFFF;
	}

	&-messages {
		// @include responsive(height, 2040px);
		// height: calc(100vh - calc(798 * 100vw / 1440));
		height: calc(100vh - 65px - 160px - 120px);
		@include responsive(padding, 40px, 0px, 45px);
		overflow-y: scroll;
		&-background {
			z-index: -1;
			background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url('../../assets/images/chat-bg.png') repeat;
			background-size: 25%;
			opacity: .12;
		}

		.dialog-chat & {
			height: calc(100vh - 160px - 120px);
		}

		&-date {
			@include responsive(margin, 45px, auto);
		}

		&-wrap {
			@include responsive(width, 1064px);
			margin: 0 auto;
			@include responsive(grid-row-gap, 15px);
		}
	}

	&-message {
		@include responsive(grid-column-gap, 20px);

		&-inbox {
			flex-direction: row;
		}

		&-outbox {
			flex-direction: row-reverse;
		}

		&-avatar {
			@include responsive(width, 73px);
			@include responsive(height, 73px);
			border-radius: 100%;
			overflow: hidden;

			& img {
				object-position: center;
				object-fit: cover;
			}
		}

		&-content {
			@include responsive(width, 369px);

			.chat-message-inbox & {
				flex-direction: row-reverse;
				@include responsive(margin-right, -10px);
			}

			.chat-message-outbox & {
				flex-direction: row;
				@include responsive(margin-left, -10px);
			}

			&-main {
				@include responsive(grid-row-gap, 5px);
				@include responsive(padding, 8px, 0px, 6px);

				.chat-message-inbox & {
					background: rgba(204, 204, 204, 0.44);
				}

				.chat-message-outbox & {
					background: #FFFFFF;
				}

				&-sender, &-attachments, &-text {					
					.chat-message-inbox & {
						@include responsive(padding-left, 20px);
						@include responsive(padding-right, 30px);
					}

					.chat-message-outbox & {
						@include responsive(padding-right, 20px);
						@include responsive(padding-left, 30px);
					}
				}

				&-sender {
					@extend .font-14;
					@include responsive(line-height, 19px);
					font-weight: 700;
				}

				&-text {
					@extend .font-16;
					@include responsive(line-height, 22px);
				}

				&-attachments {
					@include responsive(margin-top, 10px);
					@include responsive(margin-bottom, 10px);
					@include responsive(grid-row-gap, 10px);

					&-item {
						@include responsive(grid-column-gap, 10px);

						& img {

						}

						&-icon {
							@include responsive(width, 22px);
							@include responsive(height, 22px);
						}

						&-name {
							@extend .font-16;
							@include responsive(line-height, 22px);
							cursor: pointer;

							&:hover {
								text-decoration: underline;
							}
						}

						&-size {
							@extend .font-14;
							@include responsive(line-height, 19px);
						}
					}
				}

				&-top {
					width: calc(100% + 2px);
					@include responsive(height, 8px);
					top: -1px;
					left: -1px;
					border-top: 1px solid #000000;
					border-left: 1px solid #000000;
					border-right: 1px solid #000000;
					@include responsive(border-top-left-radius, 8px);
					@include responsive(border-top-right-radius, 8px);
				}

				&-middle {
					@include responsive(width, 30px);
					@include responsive(top, 6px);
					@include responsive(bottom, 6px);

					.chat-message-inbox & {
						right: -1px;
						border-right: 1px solid #000000;
					}

					.chat-message-outbox & {
						left: -1px;
						border-left: 1px solid #000000;						
					}
				}

				&-bottom {
					width: calc(100% + 2px);
					@include responsive(height, 18px);
					bottom: -1px;
					border-bottom: 1px solid #000000;

					.chat-message-inbox & {
						left: -1px;
						border-right: 1px solid #000000;
						@include responsive(border-bottom-right-radius, 8px);
					}

					.chat-message-outbox & {
						right: -1px;
						border-left: 1px solid #000000;
						@include responsive(border-bottom-left-radius, 8px);
					}
				}
			}

			&-tail {
				@include responsive(width, 15px);
				@include responsive(margin-top, 6px);
	            overflow: hidden;
	            position: relative;
	            border-bottom: 1px solid #000000;
	            margin-bottom: -1px;

				&:before {
            		content: '';
					@include responsive(width, 15px);
					height: 100%;
            		display: inline-block;
		            position: absolute;
		            bottom: -1px;
		            border-bottom: 1px solid #000000;

					.chat-message-inbox & {
		            	left: -1px;
			            border-right: 1px solid #000000;
			            @include responsive(border-bottom-right-radius, 18px);
						box-shadow: 0 0 0 99999px rgba(204, 204, 204, 0.44);
					}

					.chat-message-outbox & {
		            	right: -1px;
		            	border-left: 1px solid #000000;
		            	@include responsive(border-bottom-left-radius, 18px);
						box-shadow: 0 0 0 99999px #FFFFFF;
					}
				}
			}
		}

		&-date {
			@extend .font-14;
			@include responsive(line-height, 19px);
		}
	}

	&-form {
		@include responsive(width, 955px);
		@include responsive(margin, 0, auto, 120px);
		@include responsive(padding-top, 20px);
		@include responsive(grid-row-gap, 45px);

		&-input {
			@include responsive(width, 846px);
			@include responsive(min-height, 50px);
			@include responsive(padding, 0px, 20px);
			border-bottom: 1px solid #A2A2A2;

			& div {
				width: 100%;
				// @include responsive(height, 22px);
				margin: 0;
				padding: 0;
				left: 0;
				@extend .font-16;
				// @include responsive(line-height, 22px);
				border: none;
				outline: none;
				box-shadow: none;
			}

			& .react-emoji-picker > div {
				display: flex;
				justify-content: flex-end;
			}
		}

		&-action {
			@include responsive(width, 33px);
			@include responsive(height, 33px);
			cursor: pointer;
		}

		&-emoji {

			& button {
				width: 100%;
				height: 100%;
				padding: 0;
				background: url('../../assets/icons/smile-plus.svg');
				background-size: cover;

				& svg {
					display: none;
				}
			}
		}

		&-attachments {
			@include responsive(grid-column-gap, 10px);

			&-icon {
				@include responsive(width, 33px);
				@include responsive(height, 33px);
			}

			&-item {
				@include responsive(grid-column-gap, 5px);

				& span {
					@extend .font-14;
					@include responsive(line-height, 19px);
				}

				& img {
					@include responsive(width, 18px);
					@include responsive(height, 18px);
					cursor: pointer;
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.chat {
		&-top {
			@include responsive(grid-row-gap, 25px);
			@include responsive(margin-bottom, 20px);
		}

		&-select {
			height: auto;
			flex-wrap: wrap;

			&-item {
				width: 100%;
				@include responsive(height, 50px);

				&:first-child {
					border-right: none;
					border-bottom: 1px solid #A2A2A2;
				}

				& > div:first-child {
					@include responsive(width, 80px);
				}

				& > div:last-child {
					width: 100%;
					flex: 1;
				}

				&-label {
					@include responsive(padding, 10px);
					border-right: 1px solid #A2A2A2;
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
					display: flex;
					align-items: center;
				}

				&-dropdown {

					&-select {
						@include responsive(padding, 0, 10px);

						& span {
							@include responsive(font-size, 14px);
							@include responsive(line-height, 19px);
						}

						& img {
							@include responsive(width, 12px);
							@include responsive(height, 4px);
						}
					}

					&-menu {
						@include responsive(max-height, 200px);
						@include responsive(grid-row-gap, 10px);
						@include responsive(padding, 10px, 10px);

						&-item {
							@include responsive(font-size, 14px);
							@include responsive(line-height, 19px);
						}
					}
				}
			}
		}

		&-title {
			@include responsive(height, 44px);
			@include responsive(font-size, 16px);
			@include responsive(line-height, 22px);
		}

		&-messages {
			height: calc(100vh - 44px - 110px - 100px);
			@include responsive(padding, 40px, 0px, 45px);

			.dialog-chat & {
				height: calc(100vh - 110px - 100px);
			}

			&-date {
				@include responsive(margin, 45px, auto);
			}

			&-wrap {
				@include responsive(width, 320px);
				margin: 0 auto;
				@include responsive(grid-row-gap, 12px);
			}
		}

		&-message {
			@include responsive(grid-column-gap, 10px);

			&-avatar {
				@include responsive(width, 47px);
				@include responsive(height, 47px);
			}

			&-content {
				@include responsive(width, 150px);

				.chat-message-inbox & {
					@include responsive(margin-right, -5px);
				}

				.chat-message-outbox & {
					@include responsive(margin-left, -5px);
				}

				&-main {
					@include responsive(grid-row-gap, 5px);
					@include responsive(padding, 7px, 0px, 9px);

					&-sender, &-attachments, &-text {					
						.chat-message-inbox & {
							@include responsive(padding-left, 10px);
							@include responsive(padding-right, 15px);
						}

						.chat-message-outbox & {
							@include responsive(padding-right, 10px);
							@include responsive(padding-left, 15px);
						}
					}

					&-sender {
						@include responsive(font-size, 12px);
						@include responsive(line-height, 17px);
					}

					&-text {
						@include responsive(font-size, 12px);
						@include responsive(line-height, 17px);
					}

					&-attachments {
						@include responsive(margin-top, 10px);
						@include responsive(margin-bottom, 10px);
						@include responsive(grid-row-gap, 10px);

						&-item {
							@include responsive(grid-column-gap, 10px);

							&-icon {
								// @include responsive(width, 22px);
								// @include responsive(height, 22px);
								display: none;
							}

							&-name {
								@include responsive(font-size, 12px);
								@include responsive(line-height, 17px);
							}

							&-size {
								@include responsive(font-size, 12px);
								@include responsive(line-height, 17px);
							}
						}
					}

					&-top {
						width: calc(100% + 2px);
						@include responsive(height, 7px);
						top: -1px;
						left: -1px;
						border-top: 1px solid #000000;
						border-left: 1px solid #000000;
						border-right: 1px solid #000000;
						@include responsive(border-top-left-radius, 8px);
						@include responsive(border-top-right-radius, 8px);
					}

					&-middle {
						@include responsive(width, 15px);
						@include responsive(top, 5px);
						@include responsive(bottom, 5px);

						.chat-message-inbox & {
							right: -1px;
							border-right: 1px solid #000000;
						}

						.chat-message-outbox & {
							left: -1px;
							border-left: 1px solid #000000;						
						}
					}

					&-bottom {
						width: calc(100% + 2px);
						@include responsive(height, 10px);
						bottom: -1px;
						border-bottom: 1px solid #000000;

						.chat-message-inbox & {
							left: -1px;
							border-right: 1px solid #000000;
							@include responsive(border-bottom-right-radius, 8px);
						}

						.chat-message-outbox & {
							right: -1px;
							border-left: 1px solid #000000;
							@include responsive(border-bottom-left-radius, 8px);
						}
					}
				}

				&-tail {
					@include responsive(width, 7px);
					@include responsive(margin-top, 6px);
		            overflow: hidden;
		            position: relative;
		            border-bottom: 1px solid #000000;
		            margin-bottom: -1px;

					&:before {
	            		content: '';
						@include responsive(width, 7px);
						height: 100%;
	            		display: inline-block;
			            position: absolute;
			            bottom: -1px;
			            border-bottom: 1px solid #000000;

						.chat-message-inbox & {
			            	left: -1px;
				            border-right: 1px solid #000000;
				            @include responsive(border-bottom-right-radius, 18px);
							box-shadow: 0 0 0 99999px rgba(204, 204, 204, 0.44);
						}

						.chat-message-outbox & {
			            	right: -1px;
			            	border-left: 1px solid #000000;
			            	@include responsive(border-bottom-left-radius, 18px);
							box-shadow: 0 0 0 99999px #FFFFFF;
						}
					}
				}
			}

			&-date {
				@include responsive(font-size, 12px);
				@include responsive(line-height, 17px);
			}
		}

		&-form {
			@include responsive(width, 320px);
			@include responsive(margin, 0, auto, 100px);
			@include responsive(padding-top, 10px);
			@include responsive(grid-row-gap, 20px);

			&-input {
				@include responsive(width, 250px);
				@include responsive(min-height, 50px);
				@include responsive(padding, 0px, 10px);

				& div {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}
			}

			&-action {
				@include responsive(width, 21px);
				@include responsive(height, 21px);
				cursor: pointer;
			}

			&-attachments {
				@include responsive(grid-column-gap, 10px);

				&-icon {
					@include responsive(width, 21px);
					@include responsive(height, 21px);
				}

				&-item {
					@include responsive(grid-column-gap, 5px);

					& span {
						@include responsive(font-size, 12px);
						@include responsive(line-height, 17px);
					}

					& img {
						@include responsive(width, 12px);
						@include responsive(height, 12px);
					}
				}
			}
		}
	}
}
