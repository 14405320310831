@import '../../assets/styles/partial.scss';

.popup {
	@include responsive(width, 630px);
	height: auto;
	@include responsive(min-height, 202px);
	@include responsive(grid-row-gap, 25px);
	@include responsive(padding, 30px, 0px);
	background: var(--White, #FFF);

	&-shadow {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.65);
		z-index: 2;
	}

	&-close {
		@include responsive(top, 13px);
		@include responsive(right, 18.9px);
		cursor: pointer;
	}

	&-title, &-text {
		@extend .font-20;
		@include responsive(line-height, 28px);
		text-align: center;
		color: #000000;
	}
}

.confirm-register {
	@include responsive(height, 300px);
	@include responsive(grid-row-gap, 45px);

	& .popup-text {
		@include responsive(width, 490px);
	}
}

.forgot-password {
	@include responsive(grid-row-gap, 45px);
	@include responsive(padding, 45px, 0px);

	& .popup-body {
		@include responsive(width, 497px);
		@include responsive(grid-row-gap, 20px);
	}

	&-field {
		@include responsive(width, 413px);
		@include responsive(height, 48px);
		@include responsive(padding, 10px);
		border-bottom: 1px solid var(--Grey, #DDD); 

		& input {
			border: none;
			outline: none;
			color: var(--Black, #000);
			@extend .font-20;
		}

		&-error {
			border-bottom: 1px solid var(--Red, #C53939) !important;
		}
	}

	& .popup-text {
		@extend .font-16;
		@include responsive(line-height, 22px);
	}
}

.delete-account {

	& .popup-text br {
		display: none;
	}
}

.create-favorites-list {
	@include responsive(grid-row-gap, 45px);
	@include responsive(padding, 49px, 106px);

	& .popup-body {
		@include responsive(grid-row-gap, 45px);
	}

	.popup-title {
		@extend .font-helvetica;
		@include responsive(font-size, 28px);
		@include responsive(line-height, 39px);
	}

	&-field {
		@include responsive(width, 418px);
		@include responsive(height, 48px);
		@include responsive(padding, 10px);
		border-bottom: 1px solid var(--Grey, #DDD); 

		& input {
			border: none;
			outline: none;
			color: var(--Black, #000);
			@extend .font-20;
		}

		&-error {
			border-bottom: 1px solid var(--Red, #C53939) !important;
		}
	}
}

.add-favorite {
	@include responsive(grid-row-gap, 45px);
	@include responsive(padding, 49px, 106px);

	& .popup-body {
		@include responsive(grid-row-gap, 45px);
	}

	.popup-title {
		@extend .font-helvetica;
		@include responsive(font-size, 28px);
		@include responsive(line-height, 39px);
	}

	&-lists {
		@include responsive(width, 330px);
		@include responsive(grid-row-gap, 20px);

		&-item {
			@include responsive(height, 80px);
			@extend .font-20;
			@include responsive(line-height, 28px);
			cursor: pointer;

			&:not(:last-child) {
				border-bottom: 1px solid var(--Dark-grey, #A2A2A2);
			}
		}
	}
}

.add-order-item {
	@include responsive(grid-row-gap, 45px);
	@include responsive(padding, 45px, 0px);

	& .popup-body {
		@include responsive(width, 497px);
		@include responsive(grid-row-gap, 20px);
	}

	&-form {
		@include responsive(grid-column-gap, 20px);
		@include responsive(grid-row-gap, 20px);

		&-field {
			width: 100%;
			@include responsive(height, 48px);
			@include responsive(padding, 10px);
			border-bottom: 1px solid var(--Grey, #DDD);

			&-amount, &-price {
				@include responsive(width, 238.5px);
			}

			& input {
				border: none;
				outline: none;
				color: var(--Black, #000);
				@extend .font-20;
			}

			&-error {
				border-bottom: 1px solid var(--Red, #C53939) !important;
			}
		}
	}
}

.select-subscription {

	& .popup-body {
		@include responsive(grid-row-gap, 25px);
	}

	&-form {
		@include responsive(grid-row-gap, 20px);

		& label {
			@include responsive(grid-column-gap, 10px);
			@extend .font-20;
			cursor: pointer;

			& input {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.popup {
		@include responsive(width, 320px);
		@include responsive(min-height, 119px);
		@include responsive(grid-row-gap, 15px);
		@include responsive(padding, 20px, 0px);

		&-close {
			@include responsive(top, 6px);
			@include responsive(right, 9px);
		}

		&-text {
			@include responsive(font-size, 12px);
			@include responsive(line-height, 17px);
		}
	}

	.confirm-register {
		@include responsive(height, 170px);
		@include responsive(grid-row-gap, 15px);

		& .popup-text {
			@include responsive(width, 230px);
		}
	}

	.forgot-password {
		@include responsive(grid-row-gap, 15px);
		@include responsive(padding, 20px, 0px);

		& .popup-body {
			@include responsive(width, 261px);
			@include responsive(grid-row-gap, 15px);
		}

		& .popup-title {
			@include responsive(font-size, 16px);
			@include responsive(line-height, 22px);
		}

		&-field {
			width: 100%;
			@include responsive(height, 35px);
			@include responsive(padding, 10px);

			& input {
				@include responsive(font-size, 11px);
				@include responsive(line-height, 15px);
			}
		}

		& .popup-text {
			@include responsive(width, 252px);
			@include responsive(font-size, 12px);
			@include responsive(line-height, 17px);
		}
	}

	.delete-account {
		
		& .popup-text br {
			display: block;
		}
	}

	.create-favorites-list {
		@include responsive(grid-row-gap, 25px);
		@include responsive(padding, 20px);

		& .popup-body {
			@include responsive(grid-row-gap, 25px);
		}

		.popup-title {
			@extend .font-helvetica;
			@include responsive(font-size, 16px);
			@include responsive(line-height, 22px);
		}

		&-field {
			@include responsive(width, 280px);
			@include responsive(height, 35px);
			@include responsive(padding, 8px);
			text-align: center;

			& input {
				@include responsive(font-size, 14px);
				@include responsive(line-height, 19px);
			}
		}
	}

	.add-favorite {
		@include responsive(grid-row-gap, 25px);
		@include responsive(padding, 20px);

		& .popup-body {
			@include responsive(grid-row-gap, 25px);
		}

		.popup-title {
			@include responsive(font-size, 16px);
			@include responsive(line-height, 22px);
		}

		&-lists {
			@include responsive(width, 230px);
			@include responsive(grid-row-gap, 20px);

			&-item {
				@include responsive(height, 35px);
				@include responsive(font-size, 14px);
				@include responsive(line-height, 19px);
			}
		}
	}

	.add-order-item {
		@include responsive(grid-row-gap, 25px);
		@include responsive(padding, 20px);

		& .popup-body {
			@include responsive(width, 261px);
			@include responsive(grid-row-gap, 15px);
		}

		&-form {
			@include responsive(grid-column-gap, 20px);
			@include responsive(grid-row-gap, 20px);

			&-field {
				@include responsive(height, 35px);
				@include responsive(padding, 10px);

				& input {
					@include responsive(font-size, 11px);
					@include responsive(line-height, 15px);
				}
				
				&-amount, &-price {
					@include responsive(width, 261px);
				}
			}
		}
	}
}
