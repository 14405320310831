@function replace-px ($value) {
    @return calc($value / 1px);
}

@mixin responsive($property, $values...) {
  $originalValues: ();

  @each $value in $values {
    $originalValues: append($originalValues, $value);
  }

  #{$property}: $originalValues;

  @media screen and (max-width: 1440px) and (min-width: 577px) {
    $responsiveValues: ();

    @each $value in $values {
      @if $value != 0 and $value != auto {
        $responsiveValues: append($responsiveValues, calc(replace-px($value) * 100vw / 1440));
      }
      @else {
        $responsiveValues: append($responsiveValues, $value);
      }
    }

    #{$property}: $responsiveValues;
  }

  @media screen and (max-width: 576px) {
    $responsiveValues: ();

    @each $value in $values {
      @if $value != 0 and $value != auto {
        $responsiveValues: append($responsiveValues, calc(replace-px($value) * 100vw / 360));
      }
      @else {
        $responsiveValues: append($responsiveValues, $value);
      }
    }

    #{$property}: $responsiveValues;
  }
}
