@import '../../assets/styles/partial.scss';

.products-grid {
	@include responsive(width, 955px);
	@include responsive(grid-column-gap, 20px);
	@include responsive(grid-row-gap, 65px);
}

.products-popular,
.products-viewed {
	@include responsive(grid-row-gap, 45px);

	&-title {
		@extend .font-24;
		@include responsive(line-height, 33px);
	}

	&-items {
		@include responsive(width, 1065px);
		@include responsive(grid-column-gap, 20px);

		& .product-card {
			@include responsive(height, 324px);

			&-image {
				@include responsive(height, 240px);
				object-fit: cover;
				object-position: center;
			}

			&-main {
				@include responsive(grid-row-gap, 10px);
			}

			&-info {
				@include responsive(grid-row-gap, 1px);

				&-name {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}

				&-brand {
					@include responsive(font-size, 11px);
					@include responsive(line-height, 15px);
				}

				&-price {
					@include responsive(font-size, 16px);
					@include responsive(line-height, 22px);
				}

				&-qty {
					@include responsive(font-size, 11px);
					@include responsive(line-height, 15px);
				}
			}
		}
	}
}

.product-card {
	@include responsive(width, 305px);
	@include responsive(height, 480px);
	@include responsive(grid-row-gap, 20px);

	&-main {
		@include responsive(grid-row-gap, 15px);
	}

	&-image {
		width: 100%;
		@include responsive(height, 297px);

		& img {
			width:100%;
	        height:100%;
	        object-position: center;
	        object-fit: cover;
		}
	}

	&-info {
		@include responsive(grid-row-gap, 5px);
		color: var(--Black, #000);
		@extend .font-helvetica;

		&-append {

			& > div {
				@include responsive(grid-row-gap, 5px);
			}
		}

		&-name {
			@include responsive(font-size, 16px);
		}

		&-brand {
			color: var(--Dark-grey, #A2A2A2);
			@include responsive(font-size, 12px);
		}

		&-price {
			@include responsive(font-size, 24px);
		}

		&-qty {
			@include responsive(font-size, 12px);
		}

		&-amount {

			&-select {
				@include responsive(grid-column-gap, 5px);

				& button {
					border-radius: 100%;
				}

				&-input {
					@include responsive(width, 45px);
					@include responsive(padding, 5px);
					border-bottom: 1px solid var(--Dark-grey, #A2A2A2);

					& input {
						border: none;
						outline: none;
						@extend .font-20;
						text-align: center;
					}
				}
			}
		}
	}

	&-favorite {
		@include responsive(width, 45px);
		@include responsive(height, 45px);
		cursor: pointer;

		& img {
			width:100%;
	        height:100%;
		}
	}
}

.product {	

	&-blocks {
		@include responsive(margin, 100px, auto, 120px);
		@include responsive(grid-row-gap, 85px);
	}

	&-data {

		&-left {
			@include responsive(grid-row-gap, 45px);
		}

		&-gallery {
			@include responsive(width, 522px);

			&-main {
				@include responsive(height, 450px);
				@include responsive(margin-bottom, 25px);

				& > img {
					width:100%;
			        height:100%;
			        object-position: center;
			        object-fit: cover;
				}

				&-actions {
					@include responsive(top, 10px);
					@include responsive(right, 10px);
					@include responsive(grid-row-gap, 10px);
				}
			}

			&-thumbs {
				@include responsive(grid-column-gap, 20px);
				@include responsive(grid-row-gap, 20px);
				@include responsive(margin-bottom, 20px);
			}

			&-thumb {
				@include responsive(width, 88px);
				@include responsive(height, 88px);
				cursor: pointer;

				& img {
					width:100%;
			        height:100%;
			        object-position: center;
			        object-fit: cover;
				}
			}

			&-download {
				@extend .font-16;
				cursor: pointer;
			}

			& .product-error-message {
				@include responsive(bottom, -20px);
			}
		}

		&-buttons {
			@include responsive(grid-row-gap, 12px);
		}

		&-right {
			@include responsive(grid-row-gap, 45px);

			& .product-error-message {
				@include responsive(top, -20px);
			}
		}

		&-field {
			@include responsive(height, 80px);

			&-categories {
				height: auto !important;
			}

			&-label {
				@include responsive(width, 279px);
				@include responsive(padding, 20px, 10px);
				border-bottom: 1px solid var(--Dark-grey, #A2A2A2);
				border-right: 1px solid var(--Dark-grey, #A2A2A2);
				-webkit-text-stroke-width: 0.20000000298023224;
				-webkit-text-stroke-color: var(--Black, #000);
				@extend .font-20;
			}

			&-input {
				@include responsive(width, 459px);
				@include responsive(padding, 20px, 0px, 20px, 20px);
				border-bottom: 1px solid var(--Dark-grey, #A2A2A2);

				& input {
					border: none;
					outline: none;
					@extend .font-20;
				}

				& span {
					@extend .font-20;
				}
			}
				
			&-icon {
				@include responsive(width, 24px);
				@include responsive(height, 24px);
				cursor: pointer;
			}
		}
	}

	&-description {
		@include responsive(width, 1064px);
		@include responsive(grid-row-gap, 45px);
		margin: 0 auto;

		&-title {
			@extend .font-24;
			@include responsive(line-height, 33px);
			text-align: center;
		}

		&-field {
			@include responsive(min-height, 195px);
			@include responsive(padding, 20px, 25px);
			border-top: 1px solid #A2A2A2;
			border-bottom: 1px solid #A2A2A2;

			&-textarea {
				color: var(--Black, #000);
				@extend .font-24;
				@include responsive(line-height, 33px);
				text-align: left;

				& textarea {
					border: none;
					outline: none;
					box-shadow: none;
					color: var(--Black, #000);
					@extend .font-24;
					@include responsive(line-height, 33px);
				}
			}

			&-icon {
				@include responsive(width, 24px);
				@include responsive(height, 24px);
				cursor: pointer;
			}
		}

		&-actions {
			@include responsive(grid-column-gap, 20px);
		}
	}

	&-calendar {
		@include responsive(grid-row-gap, 20px);

		&-wrap {
			@include responsive(grid-row-gap, 65px);
		}

		&-title {
			@extend .font-24;
			@include responsive(line-height, 33px);
		}

		&-body {
			@include responsive(width, 846px);
			@include responsive(grid-row-gap, 45px);
		}

		&-year {
			@include responsive(width, 196px);
			@include responsive(height, 38px);
		}

		&-month {
			@include responsive(height, 53px);
		}

		&-year, &-month {
			@include responsive(padding, 0, 20px);

			&-prev, &-next {
				@include responsive(width, 13px);
				@include responsive(height, 28px);
				cursor: pointer;
			}

			&-current {
				@extend .font-20;
				@include responsive(line-height, 28px);
			}
		}

		&-dates {
			@include responsive(grid-row-gap, 10px);

			&-item {
				@include responsive(width, 112px);
				@include responsive(height, 114.2px);
  				border: 1px solid var(--Black, #000);

  				&-day, &-legend {
  					@include responsive(height, 51.1px);
  				}

  				&-day {
					@extend .font-24;
					@include responsive(line-height, 33px);
  				}

  				&-marker {
					&-green {
						background: #70CF58;
					}
  				}

  				&-legend {
					@extend .font-16;
					@include responsive(line-height, 22px);
					background: var(--Black, #000) !important;
					color: var(--White, #FFF) !important;
  				}
			}

		}

		&-legend {
			@include responsive(grid-column-gap, 50px);

			&-item {
				@include responsive(grid-column-gap, 20px);

				&-marker {
					@include responsive(width, 30px);
					@include responsive(height, 30px);
					border-radius: 100%;

					&-red {
						background: #D22525;
					}

					&-green {
						background: #70CF58;
					}
				}

				&-text {
					@extend .font-16;
					@include responsive(line-height, 22px);
				}
			}
		}
	}

	&-comments {
		@include responsive(width, 1064px);
		@include responsive(grid-row-gap, 65px);
		margin: 0 auto;

		&-title {
			@extend .font-24;
			@include responsive(line-height, 33px);
		}

		&-carousel {
			@include responsive(width, 851px);

			&-nav {
				@include responsive(width, 15px);
				@include responsive(height, 33px);
				@include responsive(top, 38px);
				z-index: 2;
				cursor: pointer;

				&-prev {
					@include responsive(left, -91.5px);
				}

				&-next {
					@include responsive(right, -91.5px);
				}

				& img {
					width: 100%;
				}
			}

			&-item {
				display: flex !important;
				@include responsive(grid-column-gap, 22px);

				&-image {
					@include responsive(width, 86px);
					@include responsive(height, 86px);
					border-radius: 100%;
					overflow: hidden;

					& img {
						width: 100%;
						height: 100%;
				        object-position: center;
				        object-fit: cover;
					}
				}

				&-content {
					@include responsive(width, 305px);
					@include responsive(grid-row-gap, 15px);
				}

				&-author {
					@extend .font-20;
					@include responsive(line-height, 28px);
				}

				&-text {
					@extend .font-16;
					@include responsive(line-height, 22px);

					&-mobile {
						display: none;
					}
				}
			}
		}

		&-none {
			@extend .font-20;
			@include responsive(line-height, 28px);
			color: #A2A2A2;
		}

		&-form {
			@include responsive(grid-row-gap, 45px);

			&-field {
				@include responsive(grid-column-gap, 20px);

				& textarea {
					@include responsive(width, 846px);
					@include responsive(height, 50px);
					@include responsive(padding, 14px, 20px);
					@extend .font-20;
					@include responsive(line-height, 22px);
					border: none;
					outline: none;
					box-shadow: none;
					border-bottom: 1px solid #A2A2A2;
				}

				& img {
					@include responsive(width, 33px);
					@include responsive(height, 33px);
					cursor: pointer;
				}
			}

		}
	}

	&-history {
		@include responsive(width, 1064px);
		@include responsive(grid-row-gap, 65px);
		margin: 0 auto;

		&-title {
			@extend .font-24;
			@include responsive(line-height, 33px);
		}

		&-table {
			border-collapse: collapse;

			&-row {
				cursor: pointer;
			}

			&-cell {
				width: 25%;
				@include responsive(height, 57.33px);
				padding: 6px !important;
				font-family: 'Helvetica' !important;
				font-style: normal !important;
				font-weight: 400 !important;
				font-size: 20px !important;
				line-height: 28px !important;
				border: 1px solid #A2A2A2 !important;
				text-align: center !important;
			}
		}
	}
}

@media screen and (max-width: 1440px) and (min-width: 577px) {
	.product-history-table-cell  {
		padding: calc(6 * 100vw / 1440) !important;
		font-size: calc(20 * 100vw / 1440) !important;
		line-height: calc(28 * 100vw / 1440) !important;
	}
}

@media screen and (max-width: 576px) {
	.products-grid {
		width: 100%;
		@include responsive(grid-column-gap, 15px);
		@include responsive(grid-row-gap, 20px);
	}

	.products-popular,
	.products-viewed {
		@include responsive(grid-row-gap, 45px);

		&-title {
			@extend .font-24;
			@include responsive(line-height, 33px);
		}

		&-items {
			flex-wrap: wrap;
			@include responsive(width, 320px);
			@include responsive(grid-column-gap, 15px);

			& .product-card {
				// @include responsive(height, 324px);
				height: auto;

				&-image {
					@include responsive(height, 187px);
					object-fit: cover;
					object-position: center;
				}

				&-main {
					@include responsive(grid-row-gap, 10px);
				}

				&-info {
					@include responsive(grid-row-gap, 1px);

					&-name {
						@include responsive(font-size, 12px);
						@include responsive(line-height, 17px);
					}

					&-brand {
						@include responsive(font-size, 11px);
						@include responsive(line-height, 15px);
					}

					&-price {
						@include responsive(font-size, 16px);
						@include responsive(line-height, 22px);
					}

					&-qty {
						@include responsive(font-size, 11px);
						@include responsive(line-height, 15px);
					}
				}

				&:nth-child(5) {
					display: none;
				}
			}
		}
	}

	.product-card {
		@include responsive(width, 152.5px);
		// @include responsive(height, 327px);
		height: auto;
		@include responsive(grid-row-gap, 15px);

		&-main {
			@include responsive(grid-row-gap, 10px);
		}

		&-image {
			@include responsive(height, 187px);
			object-fit: cover;
			object-position: center;
		}

		&-info {
			@include responsive(grid-row-gap, 5px);

			&-name {
				@include responsive(font-size, 12px);
				@include responsive(line-height, 17px);
				order: 1;
			}

			&-brand {
				@include responsive(font-size, 11px);
				@include responsive(line-height, 15px);
				order: 3;
			}

			&-price {
				@include responsive(font-size, 16px);
				@include responsive(line-height, 22px);
				order: 2;
			}

			&-qty {
				@include responsive(font-size, 11px);
				@include responsive(line-height, 15px);
				order: 4;
			}

			&-amount {

				&-select {
					@include responsive(grid-column-gap, 5px);

					&-input {
						@include responsive(width, 30px);
						@include responsive(padding, 5px);

						& input {
							@include responsive(font-size, 16px);
							@include responsive(line-height, 22px);
						}
					}
				}
			}
		}

		&-add {
			width: 100%;
		}

		&-favorite {
			display: none;
			@include responsive(width, 30px);
			@include responsive(height, 30px);
		}
	}

	.product {	

		&-blocks {
			@include responsive(margin, 25px, auto, 100px);
			@include responsive(grid-row-gap, 45px);
		}

		&-data {
			flex-direction: column;
			@include responsive(grid-row-gap, 45px);

			&-left {
				@include responsive(grid-row-gap, 20px);
			}

			&-gallery {
				// @include responsive(width, 522px);
				width: 100%;

				&-main {
					@include responsive(height, 212px);
					@include responsive(margin-bottom, 10px);

					&-actions {
						@include responsive(top, 10px);
						@include responsive(right, 10px);
						@include responsive(grid-row-gap, 10px);
					}
				}

				&-thumbs {
					@include responsive(grid-column-gap, 15px);
					@include responsive(grid-row-gap, 15px);
					@include responsive(margin-bottom, 20px);
				}

				&-thumb {
					@include responsive(width, 68.75px);
					@include responsive(height, 55px);
				}

				&-download {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}

				& .product-error-message {
					@include responsive(bottom, -20px);
				}
			}

			&-buttons {
				@include responsive(grid-row-gap, 10px);

				& button {
					width: 100%;

					& span {
						width: auto;
						flex: unset;
					}
				}
			}

			&-right {
				@include responsive(grid-row-gap, 20px);

				& .product-error-message {
					@include responsive(top, -20px);
				}
			}

			&-field {
				@include responsive(height, 44px);

				&-label {
					@include responsive(width, 160px);
					@include responsive(padding, 15px, 10px);
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}

				&-input {
					@include responsive(width, 160px);
					@include responsive(padding, 15px, 10px);

					& input, & span {
						@include responsive(font-size, 14px);
						@include responsive(line-height, 19px);
					}
				}
					
				&-icon {
					@include responsive(width, 17px);
					@include responsive(height, 17px);
				}
			}
		}

		&-calendar {
			@include responsive(grid-row-gap, 20px);

			&-wrap {
				@include responsive(grid-row-gap, 45px);
			}

			&-title {
				@include responsive(font-size, 20px);
				@include responsive(line-height, 28px);
			}

			&-body {
				// @include responsive(width, 846px);
				width: 100%;
				@include responsive(grid-row-gap, 45px);
			}

			&-year {
				@include responsive(width, 108px);
				@include responsive(height, 19px);
				padding: 0;
			}

			&-month {
				@include responsive(height, 19px);
				@include responsive(padding, 0, 20px);
			}

			&-year, &-month {

				&-prev, &-next {
					@include responsive(width, 8px);
					@include responsive(height, 16px);
				}

				&-current {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}
			}

			&-dates {
				@include responsive(grid-row-gap, 8px);

				&-item {
					@include responsive(width, 44.86px);
					@include responsive(height, 66px);

	  				&-day, &-legend {
	  					@include responsive(height, 30px);
	  				}

	  				&-day {
						@include responsive(font-size, 12px);
						@include responsive(line-height, 17px);
	  				}

	  				&-legend {
						@include responsive(font-size, 8px);
						@include responsive(line-height, 11px);
	  				}
				}

			}

			&-legend {
				@include responsive(grid-column-gap, 10px);

				&-item {
					@include responsive(grid-column-gap, 20px);

					&-marker {
						@include responsive(width, 20px);
						@include responsive(height, 20px);
					}

					&-text {
						@include responsive(font-size, 14px);
						@include responsive(line-height, 19px);
					}
				}
			}
		}

		&-description {
			width: 100%;
			@include responsive(grid-row-gap, 20px);

			&-title {
				@include responsive(font-size, 20px);
				@include responsive(line-height, 28px);
			}

			&-field {
				@include responsive(min-height, 125px);
				@include responsive(padding, 15px, 0);

				&-textarea {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
					text-align: center;

					& textarea {
						@include responsive(font-size, 14px);
						@include responsive(line-height, 19px);
					}
				}

				&-icon {
					display: none;
				}
			}

			&-actions {
				@include responsive(grid-column-gap, 20px);
			}
		}

		&-comments {
			width: 100%;
			@include responsive(grid-row-gap, 20px);

			&-title {
				@include responsive(font-size, 20px);
				@include responsive(line-height, 28px);
			}

			&-carousel {
				width: 100%;

				&-nav {
					@include responsive(width, 8px);
					@include responsive(height, 16px);
					@include responsive(top, 75.5px);

					&-prev {
						left: 0;
					}

					&-next {
						right: 0;
					}
				}

				&-item {
					flex-wrap: wrap;
					align-items: center;
					@include responsive(width, 265px);
					@include responsive(grid-column-gap, 10px);
					@include responsive(grid-row-gap, 10px);
					margin: 0 auto;

					&-image {
						@include responsive(width, 62px);
						@include responsive(height, 62px);
					}

					&-content {
						width: calc(100% - calc(72 * 100vw / 360));
					}

					&-author {
						@include responsive(font-size, 16px);
						@include responsive(line-height, 22px);
					}

					&-text {
						display: none;

						&-mobile {
							display: block;
							@include responsive(font-size, 14px);
							@include responsive(line-height, 19px);
						}
					}
				}
			}

			&-none {
				@include responsive(font-size, 14px);
				@include responsive(line-height, 19px);
			}

			&-form {
				@include responsive(grid-row-gap, 20px);

				&-field {
					@include responsive(grid-column-gap, 11px);

					& textarea {
						@include responsive(width, 279px);
						@include responsive(height, 50px);
						@include responsive(padding, 15px, 10px);
						@include responsive(font-size, 14px);
						@include responsive(line-height, 19px);
					}

					& img {
						@include responsive(width, 21px);
						@include responsive(height, 21px);
					}
				}

				&-submit {

					& button {
						@include responsive(width, 152px);
					}
				}
			}
		}
	}
}
