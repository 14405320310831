@import '../../assets/styles/partial.scss';

.stats {

	&-body {
		@include responsive(margin, 100px, auto, 120px);
		@include responsive(grid-column-gap, 25px);
		@include responsive(grid-row-gap, 25px);

		& > div {
			width: calc(50% - 12.5px);

			& > canvas {
				width: 100%;
				height: auto;
			}
		}
	}
}
