@import './_mixins.scss';

.font-helvetica {
	font-family: Helvetica;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.font-14 {
	@extend .font-helvetica;
	@include responsive(font-size, 14px);
}

.font-16 {
	@extend .font-helvetica;
	@include responsive(font-size, 16px);
}

.font-20 {
	@extend .font-helvetica;
	@include responsive(font-size, 20px);
}

.font-24 {
	@extend .font-helvetica;
	@include responsive(font-size, 24px);
}

.font-32 {
	@extend .font-helvetica;
	@include responsive(font-size, 32px);
}
