@import '../../assets/styles/partial.scss';

.orders {

	&-body {
		@include responsive(margin, 100px, auto, 150px);
		@include responsive(grid-row-gap, 45px);
	}

	&-table {
		border-collapse: collapse;

		&-row {
			cursor: pointer;
		}

		&-cell {
			@include responsive(width, 256.8px);
			@include responsive(height, 85.5px);
			padding: 6px !important;
			font-family: 'Helvetica' !important;
			font-style: normal !important;
			font-weight: 400 !important;
			font-size: 20px !important;
			line-height: 28px !important;
			border: 1px solid #A2A2A2 !important;
			text-align: center !important;
		}
	}
}

@media screen and (max-width: 1440px) and (min-width: 577px) {
	.orders-table-cell {
		padding: calc(6 * 100vw / 1440) !important;
		font-size: calc(20 * 100vw / 1440) !important;
		line-height: calc(28 * 100vw / 1440) !important;
	}
}

.order {

	&-blocks {
		@include responsive(margin, 100px, auto, 120px);
		@include responsive(grid-row-gap, 85px);
	}

	&-customer {
		// @include responsive(height, 530px);
		@include responsive(grid-column-gap, 20px);

		&-avatar {
			@include responsive(width, 522px);
			@include responsive(height, 450px);

			& img {
				width:100%;
		        height:100%;
		        object-position: center;
		        object-fit: cover;
			}
		}

		&-right {
			@include responsive(grid-row-gap, 90px);
		}

		&-buttons {
			@include responsive(grid-row-gap, 15px);
		}

		&-info {
			@include responsive(grid-row-gap, 30px);

			&-profile {
				@include responsive(width, 630px);

				&-item {

					& > div {
						width: 50%;
						@include responsive(padding, 17.5px, 20px);
						@extend .font-20;
						@include responsive(line-height, 28px);
						border-bottom: 1px solid #A2A2A2;
					}
				}
			}

			&-own {
				@include responsive(width, 630px);

				&-item {

					&-label {
						@include responsive(width, 157px);
						@include responsive(padding, 17px, 0px, 17px, 20px);
						@extend .font-16;
						@include responsive(line-height, 22px);
						border-right: 1px solid #A2A2A2;
						border-bottom: 1px solid #A2A2A2;
					}

					&-input {
						@include responsive(padding, 17px, 20px);
						@extend .font-16;
						@include responsive(line-height, 22px);
						border-bottom: 1px solid #A2A2A2;

						& input {
							border: none;
							outline: none;
							@extend .font-16;
							@include responsive(line-height, 22px);
						}
					}
				}
			}
		}
	}

	&-details {
		@include responsive(width, 1064px);
		@include responsive(grid-row-gap, 65px);
		margin: 0 auto;

		&-title {
			@extend .font-24;
			@include responsive(line-height, 33px);
		}

		&-form {

			&-row {
				@include responsive(height, 103.5px);
				border-bottom: 1px solid #A2A2A2;

				&:first-child {
					border-top: 1px solid #A2A2A2;
				}

				& > div {
					width: 20%;
					@include responsive(padding, 6px);
					@extend .font-20;
					@include responsive(line-height, 28px);
					text-align: center;
					border-left: 1px solid #A2A2A2;

					&:last-child {
						border-right: 1px solid #A2A2A2;
					}
				}
			}
		}

		& .project-data-fields {
			@include responsive(margin-top, 20px);
		}
	}	

	&-error-message {
		@include responsive(top, -20px);
	}

	&-items {
		@include responsive(grid-row-gap, 65px);

		&-title {
			@extend .font-24;
			@include responsive(line-height, 33px);
		}

		&-table {
			border-collapse: collapse;

			&-row {
				cursor: pointer;
			}

			&-cell {
				@include responsive(width, 140.5px);
				@include responsive(height, 62px);
				padding: 8px !important;
				font-family: 'Helvetica' !important;
				font-style: normal !important;
				font-weight: 400 !important;
				font-size: 12px !important;
				line-height: 17px !important;
				border: 1px solid #A2A2A2 !important;
				text-align: center !important;

				&-num {
					@include responsive(width, 60px);
				}

				&-image {
					padding: 0 !important;

					& img {
						width:100%;
				        height:100%;
				        object-position: center;
				        object-fit: cover;
					}
				}

				&-amount {
					@include responsive(width, 105px);
				}
			}
		}

		&-mobile {
			display: none;
			@extend .font-helvetica;
		}
	}

	&-discount {
		border: none;
		outline: none;
		font-family: 'Helvetica' !important;
		font-style: normal !important;
		font-weight: 400 !important;
			@include responsive(font-size, 12px);
			@include responsive(line-height, 17px);
		text-align: center;
	}
}

@media screen and (max-width: 576px) {

	.orders {

		&-body {
			@include responsive(margin, 25px, auto, 100px);
			@include responsive(grid-row-gap, 45px);

			& .catalog-sort {
				display: flex !important;
			}
		}

		&-buttons {
			order: 3;
			@include responsive(margin-top, -25px);

			& button {
				width: 100%;
			}
		}

		&-table {

			&-cell {
				width: auto;
				@include responsive(height, 74.5px);
				padding: 2px !important;
				font-size: calc(11 * 100vw / 360) !important;
				line-height: calc(15 * 100vw / 360) !important;
			}
		}
	}

	.order {

		&-blocks {
			@include responsive(margin, 25px, auto, 100px);
			@include responsive(grid-row-gap, 45px);
		}

		&-customer {
			// @include responsive(height, 530px);
			height: auto;
			flex-direction: column;
			@include responsive(grid-row-gap, 20px);

			&-avatar {
				// @include responsive(width, 522px);
				width: 100%;
				@include responsive(height, 250px);
			}

			&-right {
				height: auto !important;
				@include responsive(grid-row-gap, 45px);

			}

			&-buttons {
				@include responsive(grid-row-gap, 10px);

				& .btn {
					width: 100%;
				}
			}

			&-info {
				@include responsive(grid-row-gap, 20px);

				&-profile {
					// @include responsive(width, 630px);
					width: 100%;

					&-item {

						& > div {
							width: 50%;
							@include responsive(padding, 15px, 10px);
							@include responsive(font-size, 14px);
							@include responsive(line-height, 19px);
						}
					}
				}
			}
		}

		&-details {
			// @include responsive(width, 1064px);
			width: 100%;
			@include responsive(grid-row-gap, 20px);
			margin: 0 auto;

			&-title {
				@include responsive(font-size, 20px);
				@include responsive(line-height, 28px);
			}

			&-form {
				flex-direction: row;

				&-row {
					flex-direction: column;
					height: auto;
					border-top: 1px solid #A2A2A2;
					border-bottom: none;

					&:first-child {
						border-left: 1px solid #A2A2A2;
					}

					& > div {
						width: 100%;
						@include responsive(height, 84.8px);
						@include responsive(padding, 10px);
						@include responsive(font-size, 14px);
						@include responsive(line-height, 19px);
						text-align: center;
						border-left: none;
						border-right: 1px solid #A2A2A2; 
						border-bottom: 1px solid #A2A2A2;
					}
				}
			}

			& .project-data-fields {
				@include responsive(margin-top, 25px);
			}
		}

		&-items {
			@include responsive(grid-row-gap, 20px);

			&-title {
				@include responsive(font-size, 20px);
				@include responsive(line-height, 28px);
			}

			&-table {
				display: none !important;
			}

			&-mobile {
				display: flex;
				@include responsive(width, 320px);
				@include responsive(padding-bottom, 20px);
				overflow-x: scroll;
				scrollbar-width: thin;
				scrollbar-color: #A2A2A2 #DDDDDD;
				scrollbar-visibility: visible;
				&::-webkit-scrollbar {
					height: 6px;
					visibility: visible;
				}
				&::-webkit-scrollbar-thumb {
  					background-color: #A2A2A2;
				}
				&::-webkit-scrollbar-track {
  					background-color: #DDDDDD;
				}

				&-item {
					@include responsive(width, 320px);

					&:first-child {
						border-left: 1px solid #A2A2A2;
					}

					&-row {
						@include responsive(min-height, 65.7px);
						@include responsive(font-size, 12px);
						@include responsive(line-height, 17px);
						border-bottom: 1px solid #A2A2A2;

						&:first-child {
							border-top: 1px solid #A2A2A2;
						}

						& > div {
							@include responsive(width, 160px);
							@include responsive(padding, 10px);
							text-align: center;
							border-right: 1px solid #A2A2A2;
						}
					}

					&-name {
						@include responsive(height, 106px);


					}

					&-image {
						@include responsive(height, 69.27px);

						& img {
							width:100%;
					        height:100%;
					        object-position: center;
					        object-fit: cover;
						}
					}
				}
			}
		}

		&-discount {
			@include responsive(font-size, 12px);
			@include responsive(line-height, 17px);
		}
	}
}
