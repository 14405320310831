@import '../../assets/styles/partial.scss';

.banner-main {
	@include responsive(height, 819px);
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('../../assets/images/banner-main-background.png'), lightgray 0px -6.577px / 100% 117.216% no-repeat;
	background-position: center;
	background-size: cover;
	@include responsive(margin-bottom, 85px);

	&-wrap {
		@include responsive(padding-top, 267px);
	}

	&-left {
		&-title {
			@include responsive(width, 532px);
			@include responsive(margin-bottom, 25px);
			color: #FFF;
			@extend .font-helvetica;
			@include responsive(font-size, 51px);
		}

		&-subtitle {
			@include responsive(width, 552px);
			@include responsive(margin-bottom, 45px);
			color: #D6D6D6;
			@extend .font-helvetica;
			@include responsive(font-size, 18px);
		}

		&-buttons {
			@include responsive(grid-column-gap, 27px);
		}

		&-more-link {
			color: #FFF;
			text-align: center;
			@extend .font-16;
		}
	}

	&-right {
		@include responsive(grid-row-gap, 20px);

		&-item {
			@include responsive(height, 101px);
			@include responsive(grid-column-gap, 20px);

			&-value {
				@include responsive(width, 205px);
				color: #DA932A;
				text-align: right;
				@extend .font-helvetica;
				@include responsive(font-size, 73px);
			}

			&-text {
				@include responsive(width, 326px);
				color: #FFF;
				@extend .font-24;
			}
		}
	}
}

.banner-registered {
	@include responsive(height, 400px);
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/images/banner-registered.png');
	background-position: center;
	background-size: cover;

	&-wrap {
		@include responsive(width, 514px);
		@include responsive(grid-row-gap, 45px);
		text-align: center;
	}

	&-title {
		@extend .font-helvetica;
		@include responsive(font-size, 51px);
		@include responsive(line-height, 70px);
		color: var(--White, #FFF);
	}

	&-subtitle {
		@extend .font-helvetica;
		@include responsive(font-size, 18px);
		@include responsive(line-height, 25px);
		color: #D6D6D6;
	}
}

.top-promotion {
	@include responsive(height, 100px);
	top: 0;
	background: rgba(255, 255, 255, 0.40);
	backdrop-filter: blur(7px);

	&-text {
		@include responsive(width, 288px);
		color: var(--White, #FFF);
		@extend .font-16;
	}

	&-counter {
		@include responsive(grid-column-gap, 18.24px);

		& .counter-item {
			@include responsive(height, 63.1px);
			@include responsive(border-radius, 2.027px);
			background: #FFF; 
			color: var(--Black, #000);
			text-align: center;
			@extend .font-helvetica;
			@include responsive(font-size, 41.048px);

			@include responsive(padding, 0, 5px);
		}
	}
}

.home-registered {
	@include responsive(margin-bottom, 120px);

	&-products {
		@include responsive(grid-row-gap, 85px);
	}
}

.home-delimiter {
	@include responsive(margin, 85px, auto);
	@include responsive(width, 951.98px);
	height: 0px;
	border: 1px solid #A2A2A2;

}

.main-block {
	@include responsive(margin-top, 85px);
	@include responsive(margin-bottom, 85px);
}

.main-block-1 {
	@include responsive(grid-column-gap, 56px);

	&-left {
		@include responsive(width, 650px);
		@include responsive(height, 622px);
	}

	&-right {
		@include responsive(width, 574px);
		
		&-top {
			@include responsive(grid-row-gap, 35px);
		}

		&-title {
			color: #000;
			@extend .font-helvetica;
			@include responsive(font-size, 54px);
		}

		&-subtitle {
			@include responsive(width, 456px);
			color: var(--Black, #000);
			@extend .font-20;
		}

		&-items {
			@include responsive(grid-column-gap, 20px);
			@include responsive(grid-row-gap, 40px);
		}

		&-item {
			@include responsive(width, 178px);
			@include responsive(height, 106px);

			&-icon {
				
				&-42 {
					@include responsive(width, 42px);
				}

				&-44 {
					@include responsive(width, 44px);
				}

				&-46 {
					@include responsive(width, 46px);
				}

				&-52 {
					@include responsive(width, 52px);
				}
			}

			&-text {
				color: #000;
				text-align: center;
				@extend .font-16;
			}
		}

		&-bottom {
			@include responsive(width, 460px);
			color: var(--Black, #000);
			@extend .font-20;
			font-weight: 700;
		}
	}
}

.main-block-2 {
	@include responsive(width, 1189px);
	@include responsive(height, 602px);
	@include responsive(margin, 85px, auto);

	&-left {
		@include responsive(width, 539px);
		@include responsive(grid-row-gap, 55px);

		&-text {
			@include responsive(width, 374px);
			color: var(--Black, #000);
			@extend .font-20;
		}

		&-problems {
			@include responsive(padding, 49px, 50px);
			@include responsive(grid-row-gap, 20px);
			@include responsive(border-radius, 9px);
			border: 1px solid var(--Dark-grey, #A2A2A2);
			background: rgba(255, 255, 255, 0.38);

			&-icons {
				@include responsive(grid-column-gap, 20px);
			}

			&-icon {
				@include responsive(width, 56px);
				@include responsive(height, 56px);
			}
		}

		&-problem {
			@include responsive(grid-column-gap, 10px);

			&-icon {
				@include responsive(width, 24px);
				@include responsive(height, 24px);
			}

			&-text {
				color: var(--Black, #000);
				@extend .font-16;
			}
		}
	}

	&-right {
		@include responsive(width, 604px);
		@include responsive(border-radius, 4px);
		background: url('../../assets/images/main-block-2.png'), lightgray 50% / cover no-repeat;

		&-advantages {
			@include responsive(width, 468px);
			@include responsive(height, 433px);
			@include responsive(padding, 30px);
			@include responsive(grid-row-gap, 7px);
			@include responsive(border-radius, 4px);
			background: #FFF;
		}

		&-advantage {
			@include responsive(padding, 10px, 15px);
			@include responsive(grid-column-gap, 10px);

			&-icon {
				@include responsive(width, 27px);
				@include responsive(height, 27px);
			}

			&-text {
				color: var(--Black, #000);
				@extend .font-16;
			}

			&-delimiter {
				display: none;
			}
		}
	}
}

.main-block-3 {
	@include responsive(height, 589px);

	&-info {
		@include responsive(width, 470px);
		@include responsive(margin-top, 23px);
		@include responsive(grid-row-gap, 40px);

		&-title {
			color: var(--Black, #000);
			@extend .font-32;
			font-weight: 700;
		}

		&-image {
			display: none;
		}

		&-subtitles {
			@include responsive(width, 430px);
			@include responsive(grid-row-gap, 50px);

			&-delimiter {
				display: none;
			}
		}

		&-subtitle {
			color: var(--Black, #000);
			@extend .font-20;
		}

		&-bottom {
			@include responsive(grid-column-gap, 45px);

			&-logo {
				@include responsive(width, 66px);
				@include responsive(height, 73px);
			}
		}
	}

	&-image {

		&-1 {
			z-index: 1;
			@include responsive(width, 528.11px);
			@include responsive(height, 417px);
			@include responsive(top, 43px);
			@include responsive(right, 195.93px);
			background: lightgray 0px -427.868px / 100% 189.968% no-repeat;
		}

		&-2 {
			z-index: 2;
			@include responsive(width, 437.61px);
			@include responsive(height, 557px);
			top: 0;
			right: 0;
			background: lightgray 50% / cover no-repeat;
		}

		&-3 {
			z-index: 3;
			@include responsive(width, 462.47px);
			@include responsive(height, 365px);
			@include responsive(right, 350.09px);
			bottom: 0;
			background: lightgray 50% / cover no-repeat;
		}
	}
}

.main-block-4 {

	&-left {
		@include responsive(width, 607px);
		@include responsive(height, 600px);
		@include responsive(border-radius, 4px);
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.37) 90%), url('../../assets/images/main-block-4.png'), lightgray 50% / cover no-repeat;
		background-position: center;

		&-wrap {
			@include responsive(width, 456px);
			@include responsive(height, 460px);
			@include responsive(margin-top, 70px);
			@include responsive(margin-left, 50px);
		}

		&-top {
			@include responsive(width, 319px);
			color: #FFF;
			@extend .font-20;
			font-weight: 700;
		}

		&-bottom {
			@include responsive(grid-row-gap, 17px);

			&-border {
				border-bottom: 1px solid var(--White, #FFF);
			}

			&-text {
				color: var(--White, #FFF);
				@extend .font-20;
			}
		}
	}

	&-right {
		@include responsive(width, 627px);
		@include responsive(grid-row-gap, 45px);

		&-problems {
			@include responsive(grid-column-gap, 19px);
			@include responsive(grid-row-gap, 19px);
		}

		&-problem {
			@include responsive(width, 304px);
			@include responsive(height, 182px);
			@include responsive(padding, 25px, 10px, 10px);
			@include responsive(grid-row-gap, 10px);
			@include responsive(border-radius, 4px);
			border: 2px solid var(--Dark-grey, #A2A2A2);

			&-icon {
				@include responsive(width, 37px);
				@include responsive(height, 37px);
			}

			&-text {
				color: var(--Black, #000);
				text-align: center;
				@extend .font-16;
			}
		}

		&-texts {
			@include responsive(grid-row-gap, 35px);
			color: var(--Black, #000);
			@extend .font-20;

			&-top {

			}
		}
	}
}

.main-block-5 {
	margin-bottom: 0;
	@include responsive(padding-top, 85px);
	@include responsive(padding-bottom, 85px);
	background: var(--Black, #000);

	&-wrap {
		@include responsive(grid-row-gap, 45px);
	}

	&-top {
		@include responsive(grid-row-gap, 15px);
	}

	&-bottom {
		@include responsive(width, 1157px);
		margin: 0 auto;
		@include responsive(grid-row-gap, 45px);
	}
}

.bottom-promotion {
	@include responsive(grid-row-gap, 30px);

	&-text {
		@include responsive(width, 500px);
		@extend .font-32;

		& br {
			display: none;
		}
	}

	&-subtext {
		@extend .font-16;
	}

	&-counter {
		@include responsive(grid-column-gap, 36px);

		& .counter-item {
			@include responsive(height, 124px);
			@include responsive(border-radius, 4px);
			background: #FFF; 
			color: var(--Black, #000);
			text-align: center;
			@extend .font-helvetica;
			@include responsive(font-size, 81px);

			@include responsive(padding, 0, 10px);
		}

		& .counter-caption {
			@include responsive(margin-top, 23.5px);
			@extend .font-16;
		}
	}
}

.banner-bottom {
	@include responsive(height, 500px);
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.93) 1.5%, rgba(0, 0, 0, 0.00) 46.22%, #000 104.36%), url('../../assets/images/banner-bottom-background.png'), lightgray 50% / cover no-repeat;
	background-position: center;
	background-size: cover;

	&-wrap {
		@include responsive(grid-row-gap, 35px);
	}

	&-title {
		@include responsive(width, 710px);
		@extend .font-24;
	}

	&-subtitle {
		@include responsive(width, 427px);
		@extend .font-16;
	}
}

@media screen and (max-width: 576px) {
	.banner-main {
		@include responsive(height, 800px);
		@include responsive(margin-bottom, 45px);

		&-wrap {
			@include responsive(padding-top, 282px);
			flex-direction: column;
			@include responsive(grid-row-gap, 116px);
		}

		&-left {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			&-title {
				@include responsive(width, 232px);
				@include responsive(margin-bottom, 15px);
				@extend .font-helvetica;
				@include responsive(font-size, 24px);
				@include responsive(line-height, 33px);
			}

			&-subtitle {
				@include responsive(width, 288px);
				@include responsive(margin-bottom, 25px);
				@extend .font-helvetica;
				@include responsive(font-size, 14px);
				@include responsive(line-height, 19px);
			}

			&-buttons {
				flex-direction: column;
				@include responsive(grid-row-gap, 15px);
			}

			&-more-link {
				@extend .font-helvetica;
				@include responsive(font-size, 14px);
				@include responsive(line-height, 19px);
			}
		}

		&-right {
			flex-direction: row;
			@include responsive(grid-column-gap, 11px);

			&-item {
				flex-direction: column;
				@include responsive(width, 99.33px);
				height: auto;
				@include responsive(grid-row-gap, 10px);

				&-value {
					width: auto;
					justify-content: center;
					text-align: center;
					@include responsive(font-size, 35px);
					@include responsive(line-height, 48px);
				}

				&-text {
					width: auto;
					text-align: center;
					@extend .font-helvetica;
					@include responsive(font-size, 11px);
					@include responsive(line-height, 15px);
				}
			}
		}
	}

	.banner-registered {
		@include responsive(height, 400px);

		&-wrap {
			@include responsive(width, 320px);
			@include responsive(grid-row-gap, 45px);
			text-align: center;
		}

		&-title {
			@extend .font-helvetica;
			@include responsive(font-size, 51px);
			@include responsive(line-height, 70px);
		}

		&-subtitle {
			@extend .font-helvetica;
			@include responsive(font-size, 18px);
			@include responsive(line-height, 25px);
		}
	}

	.top-promotion {
		@include responsive(height, 100px);

		&-wrap {
			flex-wrap: wrap;
			justify-content: center;
		}

		&-text {
			@include responsive(width, 185px);
			@extend .font-helvetica;
			@include responsive(font-size, 11px);
			@include responsive(line-height, 15px);
		}

		&-counter {
			@include responsive(grid-column-gap, 10px);

			& .counter-item {
				@include responsive(height, 32.06px);
				@include responsive(border-radius, 1.02128px);
				@include responsive(font-size, 20.6809px);
				@include responsive(line-height, 29px);
				@include responsive(padding, 0, 3px);
			}
		}
	}

	.home-delimiter {
		@include responsive(margin, 45px, auto);
		@include responsive(width, 320px);

	}

	.main-block {
		@include responsive(margin-top, 45px);
		@include responsive(margin-bottom, 45px);
	}

	.main-block-1 {
		// @include responsive(grid-column-gap, 56px);

		&-left {
			display: none;
		}

		&-right {
			width: 100%;
			@include responsive(grid-row-gap, 40px);
			
			&-top {
				@include responsive(grid-row-gap, 20px);
			}

			&-title {
				@include responsive(font-size, 24px);
				@include responsive(line-height, 33px);
			}

			&-subtitle {
				@include responsive(width, 237px);
				@extend .font-helvetica;
				@include responsive(font-size, 14px);
				@include responsive(line-height, 19px);
			}

			&-items {
				@include responsive(grid-column-gap, 25px);
				@include responsive(grid-row-gap, 25px);
				justify-content: center;
			}

			&-item {
				@include responsive(width, 115px);
				height: auto;
				@include responsive(grid-row-gap, 10px);

				&-icon {
					
					&-31 {
						@include responsive(width, 31px);
					}

					&-32 {
						@include responsive(width, 32px);
					}

					&-33 {
						@include responsive(width, 33px);
					}

					&-37 {
						@include responsive(width, 37px);
					}
				}

				&-text {
					@extend .font-helvetica;
					@include responsive(font-size, 11px);
					@include responsive(line-height, 15px);
				}
			}

			&-bottom {
				@include responsive(width, 233px);
				@extend .font-helvetica;
				@include responsive(font-size, 16px);
				@include responsive(line-height, 22px);
			}
		}
	}

	.main-block-2 {
		width: 100%;
		height: auto;
		flex-direction: column;
		@include responsive(grid-row-gap, 45px);

		&-left {
			@include responsive(width, 320px);
			margin: 0 auto;
			flex-direction: column-reverse;
			@include responsive(grid-row-gap, 45px);

			&-text {
				@include responsive(width, 209px);
				@extend .font-helvetica;
				@include responsive(font-size, 14px);
				@include responsive(line-height, 19px);
			}

			&-problems {
				@include responsive(padding, 20px, 30px);
				@include responsive(grid-row-gap, 15px);
				@include responsive(border-radius, 9px);

				&-icons {
					@include responsive(grid-column-gap, 8px);
				}

				&-icon {
					@include responsive(width, 35px);
					@include responsive(height, 35px);
				}
			}

			&-problem {
				@include responsive(grid-column-gap, 10px);

				&-icon {
					@include responsive(width, 24px);
					@include responsive(height, 24px);
				}

				&-text {
					color: var(--Black, #000);
					@extend .font-helvetica;
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}
			}
		}

		&-right {
			width: 100%;
			@include responsive(height, 546px);
			@include responsive(border-radius, 4px);

			&-advantages {
				@include responsive(width, 310px);
				@include responsive(height, 498.52px);
				@include responsive(padding, 20px, 15px);
				@include responsive(grid-row-gap, 3px);
				@include responsive(border-radius, 4px);
			}

			&-advantage {
				@include responsive(padding, 10px, 15px);
				@include responsive(grid-column-gap, 10px);

				&-icon {
					@include responsive(width, 27px);
					@include responsive(height, 27px);
				}

				&-text {
					color: var(--Black, #000);
					@extend .font-helvetica;
					font-size: 14px;
					line-height: 19px;
				}

				&-delimiter {
					display: block;
					@include responsive(width, 280px);
					height: 0px;
					border: 1px solid #A2A2A2;

					&:last-child {
						display: none;
					}
				}
			}
		}
	}

	.main-block-3 {
		height: auto;

		&-info {
			width: 100%;
			margin-top: 0;
			@include responsive(grid-row-gap, 30px);

			&-title {
				width: 100%;
				@extend .font-helvetica;
				@include responsive(font-size, 20px);
				@include responsive(line-height, 28px);
			}

			&-image {
				display: block;
				@include responsive(height, 138px);

				& img {
					object-fit: cover;
					object-position: center;
				}
			}

			&-subtitles {
				width: 100%;
				@include responsive(grid-row-gap, 10px);

				&-delimiter {
					display: block;
					@include responsive(width, 263px);
					height: 0px;
					border: 1px solid #DDDDDD;
				}
			}

			&-subtitle {
				@extend .font-helvetica;
				@include responsive(font-size, 14px);
				@include responsive(line-height, 19px);
			}

			&-bottom {
				@include responsive(grid-column-gap, 20px);
				@include responsive(margin-top, -20px);

				&-logo {
					@include responsive(width, 38px);
					@include responsive(height, 43px);
				}
			}
		}

		&-image {
			display: none;
		}
	}

	.main-block-4 {
		width: 100vw;
		height: auto;
		flex-direction: column;
		@include responsive(grid-row-gap, 45px);


		&-left {
			width: 100%;
			@include responsive(height, 414px);
			@include responsive(border-radius, 4px);

			&-wrap {
				@include responsive(width, 320px);
				@include responsive(height, 340px);
				@include responsive(margin-top, 37px);
				@include responsive(margin-left, 20px);
			}

			&-top {
				@include responsive(width, 320px);
				@extend .font-helvetica;
				@include responsive(font-size, 14px);
				@include responsive(line-height, 19px);
			}

			&-bottom {
				@include responsive(grid-row-gap, 17px);

				&-text {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}
			}
		}

		&-right {
			@include responsive(width, 320px);
			@include responsive(grid-row-gap, 25px);

			&-problems {
				@include responsive(grid-column-gap, 15px);
				@include responsive(grid-row-gap, 20px);
			}

			&-problem {
				@include responsive(width, 152px);
				@include responsive(height, 162px);
				@include responsive(padding, 10px);
				@include responsive(grid-row-gap, 10px);
				@include responsive(border-radius, 4px);

				&-icon {
					@include responsive(width, 37px);
					@include responsive(height, 37px);
				}

				&-text {
					@include responsive(font-size, 14px);
				}
			}

			&-texts {
				@include responsive(grid-row-gap, 20px);

				&-top {
					@include responsive(font-size, 16px);
					@include responsive(line-height, 22px);
				}
				&-bottom {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}
			}
		}
	}

	.main-block-5 {
		margin-bottom: 0;
		@include responsive(padding-top, 65px);
		@include responsive(padding-bottom, 65px);

		&-wrap {
			@include responsive(grid-row-gap, 45px);
		}

		&-top {
			@include responsive(grid-row-gap, 10px);

			&-logo {
				@include responsive(width, 49px);
				@include responsive(height, 49px);
			}
		}

		&-bottom {
			@include responsive(width, 320px);
			@include responsive(grid-row-gap, 20px);

			&-button button {
				width: 100%;
			}
		}
	}

	.bottom-promotion {
		@include responsive(grid-row-gap, 20px);

		&-text {
			@include responsive(width, 330px);
			@include responsive(font-size, 20px);
			@include responsive(line-height, 28px);

			& br {
				display: block;
			}
		}

		&-subtext {
			@include responsive(font-size, 14px);
			@include responsive(line-height, 19px);
		}

		&-counter {
			@include responsive(grid-column-gap, 17px);

			& .counter-item {
				@include responsive(height, 54.26px);
				@include responsive(border-radius, 4px);
				@include responsive(font-size, 35.5157px);
				@include responsive(line-height, 49px);

				@include responsive(padding, 0, 5px);
			}

			& .counter-caption {
				display: none;
			}
		}
	}

	.banner-bottom {
		@include responsive(height, 307px);

		&-wrap {
			@include responsive(grid-row-gap, 20px);
		}

		&-title {
			width: 100%;
			@include responsive(font-size, 16px);
			@include responsive(line-height, 22px);
		}

		&-subtitle {
			width: 100%;
			@include responsive(font-size, 11px);
			@include responsive(line-height, 15px);
		}

		& a {
		    @include responsive(width, 152px);
		    @include responsive(height, 30px);
			@include responsive(font-size, 12px);
			@include responsive(line-height, 17px);

			& img {
				display: none;
			}
		}
	}
}
