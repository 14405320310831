@import '../../assets/styles/partial.scss';

.projects {

	&-body {
		@include responsive(margin, 100px, auto, 150px);
		@include responsive(grid-row-gap, 45px);
	}

	&-table {
		border-collapse: collapse;

		&-row {
			cursor: pointer;
		}

		&-cell {
			@include responsive(width, 320.75px);
			@include responsive(height, 85.5px);
			padding: 6px !important;
			font-family: 'Helvetica' !important;
			font-style: normal !important;
			font-weight: 400 !important;
			font-size: 20px !important;
			line-height: 28px !important;
			border: 1px solid #A2A2A2 !important;
			text-align: center !important;
		}
	}
}

@media screen and (max-width: 1440px) and (min-width: 577px) {
	.projects-table-cell {
		padding: calc(6 * 100vw / 1440) !important;
		font-size: calc(20 * 100vw / 1440) !important;
		line-height: calc(28 * 100vw / 1440) !important;
	}
}

.project {

	&-body {
		@include responsive(width, 1061px);
		@include responsive(margin, 100px, auto, 120px);
		@include responsive(grid-row-gap, 85px);
	}

	&-data {
		@include responsive(grid-row-gap, 45px);

		&-field {
			@include responsive(height, 80px);

			&:last-child {
				border-bottom: 1px solid var(--Dark-grey, #A2A2A2);
			}

			&-label {
				width: 50%;
				@include responsive(padding, 20px, 10px);
				border-top: 1px solid var(--Dark-grey, #A2A2A2);
				border-left: 1px solid var(--Dark-grey, #A2A2A2);
				border-right: 1px solid var(--Dark-grey, #A2A2A2);
				-webkit-text-stroke-width: 0.20000000298023224;
				-webkit-text-stroke-color: var(--Black, #000);
				@extend .font-20;
			}

			&-input {
				width: 50%;
				@include responsive(padding, 20px);
				border-top: 1px solid var(--Dark-grey, #A2A2A2);
				border-right: 1px solid var(--Dark-grey, #A2A2A2);

				& input {
					border: none;
					outline: none;
					@extend .font-20;
					text-align: center;
				}

				& span {
					@extend .font-20;
					text-align: center;
				}

				& .react-datepicker-wrapper {
					width: 100%;
				}

				& .react-datepicker-time__header {
					visibility: hidden;

					&:after {
						visibility: visible;
						position: absolute;
						top: calc(50% - 10px);
						left: calc(50% - 13.7px);
						content: "Час";
					}
				}
			}
				
			&-icon {
				@include responsive(width, 24px);
				@include responsive(height, 24px);
				cursor: pointer;
			}
		}

		&-buttons {
			@include responsive(grid-column-gap, 20px);
		}
	}

	&-info {
		@extend .font-20;
		@include responsive(line-height, 28px);
	}

	&-orders {
		@include responsive(grid-row-gap, 45px);

		&-title {
			@extend .font-24;
			@include responsive(line-height, 33px);
			text-align: center;
		}

		&-none {
			@include responsive(grid-row-gap, 45px);

			&-text {
				@extend .font-20;
				@include responsive(line-height, 28px);
				color: var(--Dark-grey, #A2A2A2);
				text-align: center;
			}
		}

		&-list {
			@include responsive(grid-row-gap, 85px);
		}
	}

	&-order {
		@include responsive(grid-row-gap, 85px);

		&-info {
			@include responsive(grid-row-gap, 65px);

			&-title {
				@extend .font-20;
				@include responsive(line-height, 28px);
			}

			&-body {
				@include responsive(grid-row-gap, 45px);
			}

			&-field {
				@include responsive(height, 91.5px);
				border-bottom: 1px solid var(--Dark-grey, #A2A2A2);
				border-right: 1px solid var(--Dark-grey, #A2A2A2);

				&:first-child {
					border-top: 1px solid var(--Dark-grey, #A2A2A2);
				}

				& > div {
					width: 50%;
					@extend .font-20;
					@include responsive(line-height, 28px);
					border-left: 1px solid var(--Dark-grey, #A2A2A2);
				}
			}

			&-buttons {
				@include responsive(grid-column-gap, 20px);
			}
		}

		&-items {
			@include responsive(grid-row-gap, 45px);


			&-table {
				border-collapse: collapse;

				&-row {
					cursor: pointer;
				}

				&-cell {
					@include responsive(height, 79.25px);
					padding: 6px !important;
					font-family: 'Helvetica' !important;
					font-style: normal !important;
					font-weight: 400 !important;
					font-size: 20px !important;
					line-height: 28px !important;
					border: 1px solid #A2A2A2 !important;
					text-align: center !important;

					&-num {
						@include responsive(width, 173.25px);
					}

					&-product {

						& a {
							& > div:first-child {
								@include responsive(width, 168px);

								& img {
									width:100%;
							        height:100%;
							        object-position: center;
							        object-fit: cover;
								}
							}
						}
					}

					&-amount {
						@include responsive(width, 173.25px);
					}

					&-price {
						@include responsive(width, 173.25px);
					}

					&-sum {
						@include responsive(width, 173.25px);
					}
				}
			}

			&-actions {
				@include responsive(grid-column-gap, 20px);
			}
		}

		&-discount {
			border: none;
			outline: none;
			@extend .font-20;
			text-align: center;
		}
	}

	&-error-message {
		@include responsive(top, -20px);
	}
}

@media screen and (max-width: 1440px) and (min-width: 577px) {
	.project-order-items-table-cell  {
		padding: calc(6 * 100vw / 1440) !important;
		font-size: calc(20 * 100vw / 1440) !important;
		line-height: calc(28 * 100vw / 1440) !important;
	}
}

@media screen and (max-width: 576px) {
	.projects {

		&-body {
			@include responsive(margin, 25px, auto, 100px);
			@include responsive(grid-row-gap, 20px);
		}

		&-table {

			&-cell {
				@include responsive(width, 109px);
				@include responsive(height, 60px);
				padding: 2px !important;
				font-size: calc(11 * 100vw / 360) !important;
				line-height: calc(15 * 100vw / 360) !important;

				&-date {
					@include responsive(width, 40px);
				}

				&-price {
					@include responsive(width, 65px);
				}
			}
		}
	}

	.project {

		&-body {
			width: 100%;
			@include responsive(margin, 25px, auto, 100px);
			@include responsive(grid-row-gap, 45px);
		}

		&-data {
			@include responsive(grid-row-gap, 20px);

			&-field {
				@include responsive(height, auto);
				@include responsive(min-height, 50px);

				&-label {
					@include responsive(padding, 0, 10px);
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
					text-align: center;
				}

				&-input {
					@include responsive(padding, 10px);

					& input, & span {
						@include responsive(font-size, 14px);
						@include responsive(line-height, 19px);
					}
				}
					
				&-icon {
					@include responsive(width, 24px);
					@include responsive(height, 24px);
					cursor: pointer;
				}
			}

			&-buttons {
				@include responsive(grid-column-gap, 15px);

				& span {
					width: auto;
					flex: unset !important;
				}
			}
		}

		&-info {
			@include responsive(font-size, 14px);
			@include responsive(line-height, 19px);
		}

		&-orders {
			@include responsive(grid-row-gap, 20px);

			&-title {
				@include responsive(font-size, 20px);
				@include responsive(line-height, 28px);
			}

			&-none {
				@include responsive(grid-row-gap, 20px);

				&-text {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}

				& button {
					width: 100%;
				}
			}

			&-list {
				@include responsive(grid-row-gap, 45px);
			}
		}

		&-order {
			@include responsive(grid-row-gap, 45px);

			&-info {
				@include responsive(grid-row-gap, 20px);

				&-title {
					@include responsive(font-size, 16px);
					@include responsive(line-height, 22px);
				}

				&-body {
					@include responsive(grid-row-gap, 20px);
				}

				&-field {
					@include responsive(height, 53px);

					& > div {
						@include responsive(font-size, 14px);
						@include responsive(line-height, 19px);
					}
				}

				&-buttons {
					flex-direction: column;
					@include responsive(grid-row-gap, 10px);

					& button {
						width: 100%;
					}
				}
			}

			&-items {
				@include responsive(grid-row-gap, 20px);

				&-table {

					&-cell {
						@include responsive(height, 59px);
						padding: 2px !important;
						font-size: calc(11 * 100vw / 360) !important;
						line-height: calc(15 * 100vw / 360) !important;

						&-amount {
							@include responsive(width, 77px);
						}

						&-price {
							@include responsive(width, 65px);
						}

						&-sum {
							@include responsive(width, 65px);
						}

						.dialog & {
							@include responsive(min-height, 59px);
							padding: 2px !important;
							font-size: calc(14 * 100vw / 360) !important;
							line-height: calc(19 * 100vw / 360) !important;

							& input {
								font-size: calc(14 * 100vw / 360) !important;
								line-height: calc(19 * 100vw / 360) !important;
							}

							&-num {
								@include responsive(width, 28px);
							}

							&-product {

								& a {
									flex-direction: column-reverse;

									& > div:first-child {
										width: 100%;
										@include responsive(height, 54.6px);
									}
								}
							}

							&-amount {
								@include responsive(width, 50px);
							}

							&-price {
								@include responsive(width, 82px);
							}

							&-sum {
								@include responsive(width, 82px);
							}
						}
					}
				}

				&-actions {
					flex-direction: column;
					@include responsive(grid-row-gap, 10px);

					& button {
						width: 100%;
					}
				}
			}

			&-discount {
				border: none;
				outline: none;
				@extend .font-20;
				text-align: center;
			}
		}

		&-error-message {
			@include responsive(top, -20px);
		}
	}
}
