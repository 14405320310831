@import '../../assets/styles/partial.scss';

.dialogs {

	&-body {
		@include responsive(margin, 100px, auto, 150px);
		@include responsive(grid-row-gap, 45px);
	}

	&-list {
		@include responsive(width, 1064px);

		&-item {
			@include responsive(height, 198px);
			@include responsive(padding, 25px, 0);
			@include responsive(grid-column-gap, 65px);
			border-bottom: 1px solid var(--Dark-grey, #A2A2A2);

			&:first-child {
				border-top: 1px solid var(--Dark-grey, #A2A2A2);
			}

			&-left {
				@include responsive(grid-row-gap, 5px);
			}

			&-avatar {
				@include responsive(width, 124px);
				@include responsive(height, 124px);

				&-image {
					border-radius: 100%;
					overflow: hidden;

					& img {
						width:100%;
				        height:100%;
				        object-position: center;
				        object-fit: cover;
					}
				}
			}

			&-online {
				&-marker {
					@include responsive(width, 22px);
					@include responsive(height, 22px);
					@include responsive(top, 5px);
					@include responsive(right, 8px);
					z-index: 1;
					border-radius: 100%;
					background: #00C142;
				}

				&-text {
					@extend .font-14;
					@include responsive(line-height, 19px);
				}
			}

			&-main {

				&-left {
					@include responsive(grid-row-gap, 15px);
				}
			}

			&-collocutor {
				@extend .font-24;
				@include responsive(line-height, 33px);
			}

			&-project {
				@include responsive(grid-column-gap, 20px);

				& > div {
					@extend .font-20;
					@include responsive(line-height, 28px);
					color: #A2A2A2;
				}
			}

			&-lastmessage {

				&-text {
					@include responsive(height, 33px);
					@extend .font-24;
					@include responsive(line-height, 33px);
				}

				&-datetime {
					@include responsive(grid-column-gap, 20px);

					& > div {
						@extend .font-20;
						@include responsive(line-height, 28px);
						color: #A2A2A2;
					}
				}
			}
		}
	}

	&-none {
		@extend .font-16;
		@include responsive(line-height, 22px);
		color: #A2A2A2;
	}
}

.dialog {

	&-body {
		@include responsive(width, 1061px);
		@include responsive(margin, 100px, auto, 120px);
		@include responsive(grid-row-gap, 85px);
	}

	&-data {
		@include responsive(grid-row-gap, 65px);
	}

	&-confirmation {

		&-data {
			// @include responsive(width, 630px);

			&-field {

				border-bottom: 1px solid var(--Dark-grey, #A2A2A2);

				& > div, & > a {
					@include responsive(height, 80px);
					@extend .font-20;
				}

				&-label {
					@include responsive(width, 206.75px);
					@include responsive(padding, 10px, 10px);
					border-right: 1px solid var(--Dark-grey, #A2A2A2);
				}

				&-value {
					@include responsive(width, 413.5px);
					@include responsive(padding, 20px);
				}
			}
		}

		&-buttons {
			@include responsive(grid-column-gap, 20px);
		}
	}
}

@media screen and (max-width: 576px) {
	.dialogs {

		&-body {
			@include responsive(margin, 25px, auto, 100px);
			@include responsive(grid-row-gap, 20px);
		}

		&-list {
			width: 100%;

			&-item {
				@include responsive(height, 119px);
				@include responsive(padding, 25px, 0);
				@include responsive(grid-column-gap, 10px);

				&-left {
					@include responsive(grid-row-gap, 5px);
				}

				&-avatar {
					@include responsive(width, 60px);
					@include responsive(height, 60px);
					@include responsive(flex, 0, 0, 60px);
				}

				&-online {
					&-marker {
						@include responsive(width, 13px);
						@include responsive(height, 13px);
						top: 0;
						@include responsive(right, 3px);
					}

					&-text {
						@include responsive(font-size, 12px);
						@include responsive(line-height, 17px);
					}
				}

				&-main {

					&-left {
						@include responsive(grid-row-gap, 5px);
						@include responsive(max-width, 123px);
					}
				}

				&-collocutor {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
				}

				&-project {
					@include responsive(grid-column-gap, 5px);

					& > div {
						@include responsive(font-size, 12px);
						@include responsive(line-height, 17px);
					}
				}

				&-lastmessage {

					&-text {
						@include responsive(font-size, 14px);
						@include responsive(line-height, 19px);

						span {
							width: 100%;
							display: inline-block;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}

					&-datetime {
						flex-direction: column;
						align-items: flex-end;
						@include responsive(grid-row-gap, 5px);

						& > div {
							@include responsive(font-size, 12px);
							@include responsive(line-height, 17px);
						}
					}
				}
			}
		}

		&-none {
			@include responsive(font-size, 14px);
			@include responsive(line-height, 19px);
		}
	}

	.dialog {

		&-body {
			width: 100%;
			@include responsive(margin, 45px, auto, 100px);
			@include responsive(grid-row-gap, 45px);
		}

		&-data {
			@include responsive(grid-row-gap, 45px);
		}

		&-confirmation {

			&-data {
				width: 100%;

				&-field {

					& > div, & > a {
						@include responsive(height, 80px);
						@include responsive(font-size, 14px);
						@include responsive(line-height, 19px);
					}

					&-label {
						// @include responsive(width, 84px);
						width: 33.3333%;
						@include responsive(padding, 20px, 10px);
					}

					&-value {
						// @include responsive(width, 238px);
						width: 33.3333%;
						@include responsive(padding, 10px);
					}
				}
			}

			&-buttons {
				@include responsive(grid-column-gap, 15px);
			}
		}
	}
}
