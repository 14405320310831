@import '../../assets/styles/partial.scss';

.catalog {
	@include responsive(margin-bottom, 150px);
}

.catalog-top {

	&-actions {
		@include responsive(margin-top, 100px);
		@include responsive(margin-bottom, 65px);
		@include responsive(grid-column-gap, 20px);

		&-sort {
			display: flex;
		}

		&-search {
			@include responsive(width, 304px);
			@include responsive(height, 33px);
			@include responsive(padding, 0px, 15px, 5px, 15px);
			border-bottom: 1px solid var(--Grey, #DDD);

			&-field {
				@include responsive(width, 256px);
				border: none;
				outline: none;
				box-shadow: none;
				@extend .font-16;
			}

			&-icon {
				cursor: pointer;
				@include responsive(width, 18px);
				@include responsive(height, 18px);
			}
		}
	}
}

.catalog-sort {
	@include responsive(width, 226px);
	@include responsive(grid-column-gap, 20px);
	cursor: pointer;

	&-icon {
		@include responsive(width, 24px);
		@include responsive(height, 24px);
	}

	&-dropdown {
		@include responsive(grid-column-gap, 14px);

		&-icon {
			@include responsive(width, 16px);
			@include responsive(height, 6px);
		}

		&-text {
			color: var(--Black, #000);
			@extend .font-16;
		}

		&-menu {
			@include responsive(grid-row-gap, 2px);
			@include responsive(padding, 6px, 15px);
			@include responsive(top, 33px);
			right: 0;
			z-index: 1;
  			background: var(--White, #FFF);
  			border: 3px solid var(--Black, #000);

  			&-item {
				@include responsive(width, 166px);
				@include responsive(height, 48px);
				@extend .font-16;

				&:not(:last-child) {
					border-bottom: 1px solid #A2A2A2;
				}
  			}
		}
	}	
}

.catalog-main {

	&-categories {
		@include responsive(grid-column-gap, 20px);
	}

	&-category {
		@include responsive(width, 630px);
		@include responsive(height, 471px);

		&-wrap {
			@include responsive(padding-bottom, 53.5px);
			@include responsive(grid-row-gap, 40.5px);
		}

		&-title {
			color: var(--White, #FFF);
			@extend .font-32;
		}
	}
}

.catalog-part {

	&-mobile-sort {
		display: none;
	}

	&-title {
		@include responsive(height, 103px);
		@include responsive(margin-bottom, 85px);
		color: var(--White, #FFF);
		@extend .font-32;
	}

	&-categories {
		@include responsive(grid-column-gap, 20px);
		@include responsive(grid-row-gap, 20px);
	}

	&-category {
		.catalog-part-categories-technics & {
			@include responsive(height, 276px);
		}

		.catalog-part-categories-decor & {
			@include responsive(height, 218px);

			&-cols-1 {
				@include responsive(height, 180px);
			}
		}

		color: var(--White, #FFF);
		text-align: center;
		@extend .font-32;

		&-cols {
			&-1 {
				width: 100%;				
			}
			&-2 {
				@include responsive(width, 630px);
			}
			&-3 {
				@include responsive(width, 413.33px);
			}
		}
	}
}

.catalog-registered {

	& .catalog-top-actions {
		@include responsive(margin-top, 65px);
		@include responsive(margin-bottom, 65px);
	}

	&-title {
		@include responsive(margin-bottom, 65px);
		@extend .font-24;
		@include responsive(line-height, 33px);
	}

	&-categories {
		@include responsive(grid-column-gap, 20px);
		@include responsive(grid-row-gap, 20px);
	}

	&-category {
		@include responsive(height, 164px);
		color: var(--White, #FFF);
		text-align: center;
		@extend .font-24;
		@include responsive(line-height, 33px);
	}
}

.catalog-category {

	&-title {
		@include responsive(margin-top, 65px);
		@include responsive(margin-bottom, 65px);
		@extend .font-24;
		@include responsive(line-height, 33px);
		letter-spacing: 0em;
		text-align: left;
	}

	&-products {
		@include responsive(grid-row-gap, 45px);
	}

	&-pagination {

		& button {
			font-family: 'Helvetica' !important;
			font-style: normal !important;
			font-weight: 400 !important;
			font-size: 16px !important;
			line-height: 20px !important;
			border-radius: unset !important;

			&[aria-current="true"] {
				background: #000;
			}
		}
	}
}

.catalog-filters {
	@include responsive(width, 267px);
	
	color: var(--Black, #000);

	& > div {
		@include responsive(grid-column-gap, 17px);
	}	

	& span {
		cursor: pointer;
	}

	&-chevron {
		@include responsive(width, 5px);
		@include responsive(height, 10px);

		&-opened {
			transform: rotate(90deg);
			transform-origin: center;
		}
	}

	&-arrow {
		@include responsive(width, 28px);
		@include responsive(height, 15px);
		cursor: pointer;
	}

	& ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&-level-1 {
		@include responsive(padding, 20px, 0);
		@extend .font-helvetica;
		@include responsive(font-size, 22px);
		border-top: 1px solid var(--Black, #000);
		&:last-child {
			border-bottom: 1px solid var(--Black, #000);
		}

		& > ul {
			@include responsive(margin-top, 20px);
			@include responsive(grid-row-gap, 10px);
		}
	}

	&-level-2 {
		@extend .font-16;
		@include responsive(line-height, 22px);

		& > ul {
			@include responsive(margin-top, 10px);
			@include responsive(grid-row-gap, 5px);
		}
	}

	&-level-3 {
		@extend .font-helvetica;
		@include responsive(font-size, 12px);
		@include responsive(line-height, 17px);
		color: #A2A2A2;
	}

	.catalog-tree & {

		&-level-0 {
			@include responsive(padding, 20px, 0);
			@extend .font-24;
			@include responsive(line-height, 33px);
			border-bottom: 1px solid var(--Black, #000);
			
			&:first-child {
				border-top: 1px solid var(--Black, #000);
			}

			&.catalog-filters-opened {
				padding-bottom: 0;
				border-bottom: none;
			}

			& > ul {
				margin-top: 0;
				@include responsive(grid-row-gap, 10px);
			}
		}

		&-level-1 {
			border-top: none;

			& > ul {
				@include responsive(margin-top, 10px);
				@include responsive(grid-row-gap, 5px);
			}
		}

		&-level-2 {

			& > ul {
				@include responsive(margin-top, 5px);
			}
		}
	}
}

.category-selection {
	@include responsive(width, 1198px);
	@include responsive(height, 672px);
	@include responsive(padding, 45px, 40px);
	@include responsive(grid-row-gap, 25px);
	background: #FFF;

	&-shadow {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.65);
	}

	&-close {
		@include responsive(top, 45px);
		@include responsive(right, 40px);
		cursor: pointer;
	}

	&-back {
		@include responsive(grid-column-gap, 30px);
		cursor: pointer;

		&-arrow {
			@include responsive(width, 36px);
			@include responsive(height, 28px);
		}

		&-link  {
			@extend .font-16;
			@include responsive(line-height, 22px);
		}

	}

	&-title {
		@extend .font-24;
		@include responsive(line-height, 33px);
	}

	&-categories {
		@include responsive(grid-column-gap, 20px);
		@include responsive(grid-row-gap, 20px);
	}

	&-category {
		.category-selection-categories-technics & {
			@include responsive(height, 225.5px);
		}

		.category-selection-categories-decor & {
			@include responsive(height, 125.5px);

			&-cols-1 {
				@include responsive(height, 180px);
			}
		}

		color: var(--White, #FFF);
		text-align: center;
		@extend .font-24;
		@include responsive(line-height, 33px);

		&-cols {
			&-1 {
				width: 100%;				
			}
			&-2 {
				@include responsive(width, 549px);
			}
			&-3 {
				@include responsive(width, 359.33px);
			}
		}
	}

	&-filter {
		@include responsive(max-height, 582px);
		@include responsive(padding, 40px, 60px);
		background: #FFF;
		overflow-y: scroll; 

		&-shadow {
			width: 100%;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.45);
		}

		&-close {
			@include responsive(top, 28px);
			@include responsive(right, 20px);
			cursor: pointer;
		}

		& .catalog-filters-arrow {
			display: none;
		}
	}

}

@media screen and (max-width: 576px) {
	.catalog {
		@include responsive(margin-bottom, 120px);
	}

	.catalog-top {

		&-actions {
			flex-direction: column-reverse;
			align-items: flex-end;
			@include responsive(margin-top, 25px);
			@include responsive(margin-bottom, 20px);
			@include responsive(grid-row-gap, 20px);

			&-sort {
				display: none;
			}

			&-search {
				@include responsive(width, 320px);
				@include responsive(height, 33px);
				@include responsive(padding, 0px, 0px, 5px);

				&-field {
					@include responsive(width, 302px);
				    @include responsive(font-size, 12px);
				    @include responsive(line-height, 17px);
				}

				&-icon {
					@include responsive(width, 18px);
					@include responsive(height, 18px);
				}
			}
		}
	}

	.catalog-sort {
		@include responsive(width, 144px);
		@include responsive(margin-left, 20px);
		@include responsive(margin-bottom, 45px);
		@include responsive(grid-column-gap, 10px);

		&-icon {
			@include responsive(width, 16px);
			@include responsive(height, 16px);
		}

		&-dropdown {
			@include responsive(grid-column-gap, 14px);

			&-icon {
				@include responsive(width, 16px);
				@include responsive(height, 6px);
			}

			&-text {
			    @include responsive(font-size, 14px);
			    @include responsive(line-height, 19px);
			}

			&-menu {
				@include responsive(grid-row-gap, 2px);
				@include responsive(padding, 6px, 5px);
				@include responsive(top, 33px);
	  			border: 2px solid var(--Black, #000);

	  			&-item {
					@include responsive(width, 143px);
					@include responsive(height, 27px);
				    @include responsive(font-size, 12px);
				    @include responsive(line-height, 17px);
	  			}
			}
		}	
	}

	.catalog-main {

		&-categories {
			flex-direction: column;
			@include responsive(grid-row-gap, 20px);
		}

		&-category {
			width: 100%;
			@include responsive(height, 231px);

			&-wrap {
				@include responsive(padding-bottom, 32px);
				@include responsive(grid-row-gap, 38.5px);
			}

			&-title {
			    @include responsive(font-size, 20px);
			    @include responsive(line-height, 28px);
			}

			& .btn img {
				display: none;
			}
		}
	}

	.catalog-part {

		&-mobile-sort {
			display: flex;
		}

		&-title {
			@include responsive(height, 60px);
			@include responsive(margin-bottom, 20px);
		    @include responsive(font-size, 20px);
		    @include responsive(line-height, 28px);
		}

		&-categories {
			@include responsive(grid-column-gap, 15px);
			@include responsive(grid-row-gap, 20px);
		}

		&-category {
			.catalog-part-categories-technics &, 
			.catalog-part-categories-technics & {
				@include responsive(height, 235px);

				&-mcols-1 {
					@include responsive(height, 160px);
				}
			}

			@include responsive(font-size, 16px);
			@include responsive(line-height, 22px);

			&-mcols {
				&-1 {
					width: 100%;
				}
				&-2 {
					@include responsive(width, 152.5px);				
				}
			}
		}
	}

	.catalog-category {

		&-title {
			display: none;
		}

		&-content {
			flex-direction: column;
			@include responsive(grid-row-gap, 45px);	
		}

		&-products {
			@include responsive(grid-row-gap, 45px);	
		}

		&-pagination {

			& button {
				width: 28px !important;
				min-width: 28px !important;
				height: 28px !important;
				font-family: 'Helvetica' !important;
				font-style: normal !important;
				font-weight: 400 !important;
				font-size: 12px !important;
				line-height: 17px !important;
				border-radius: unset !important;

				&[aria-current="true"] {
					background: #000;
				}
			}
		}
	}

	.catalog-filters {
		width: 100%;

		&-wrap {
			width: 100%;
			flex-direction: row;
			@include responsive(grid-column-gap, 20px);
			position: relative;
			overflow-x: scroll;
			scrollbar-color: transparent transparent;

			&.level-0 {
				@include responsive(height, 29px);
			}
			&.level-1 {
				@include responsive(height, 67px);
			}
			&.level-2 {
				@include responsive(height, 118px);
			}
		}

		& > div {
			display: inline;

			& span {
				white-space: nowrap;
			}
		}	

		& span {
			cursor: pointer;
		}

		&-chevron {
			display: none;
		}

		&-arrow {
			display: none;
		}

		& ul {
			flex-direction: row;
			position: absolute;
			left: 0;
		}

		&-level-1 {
			@include responsive(height, 29px);
			@include responsive(padding, 0, 0, 10px);
			@include responsive(font-size, 14px);
			@include responsive(line-height, 19px);

			border-top: none;

			&:last-child {
				border-bottom: none;
			}

			&.catalog-filters-opened {
				border-bottom: 2px solid #000000;
			}

			& > ul {
				margin-top: 0;
				@include responsive(grid-column-gap, 15px);
				@include responsive(grid-row-gap, 10px);
				@include responsive(top, 54px);

			}
		}

		&-level-2 {
			@include responsive(font-size, 12px);
			@include responsive(line-height, 17px);

			&.catalog-filters-opened {
				border-bottom: 1px solid #000000;
			}

			& > ul {
				margin-top: 0;
				@include responsive(grid-column-gap, 15px);
				@include responsive(grid-row-gap, 5px);
				@include responsive(top, 44px);
			}
		}

		&-level-3 {
			@include responsive(font-size, 11px);
			@include responsive(line-height, 15px);
		}

		.catalog-tree & {

			&-level-0 {
				@include responsive(height, 29px);
				@include responsive(padding, 0, 0, 10px);
				@include responsive(font-size, 14px);
				@include responsive(line-height, 19px);
				border-bottom: none;

				&:first-child {
					border-top: none;
				}

				&.catalog-filters-opened {
					border-bottom: 2px solid #000000;
				}

				& > ul {
					margin-top: 0;
					@include responsive(grid-column-gap, 15px);
					@include responsive(grid-row-gap, 10px);
					@include responsive(top, 54px);
				}
			}

			&-level-1 {
				@include responsive(font-size, 12px);
				@include responsive(line-height, 17px);
				border-top: none;

				& > ul {
					margin-top: 0;
					@include responsive(grid-column-gap, 15px);
					@include responsive(grid-row-gap, 5px);
					@include responsive(top, 44px);
				}
			}

			&-level-2 {
				@include responsive(font-size, 11px);
				@include responsive(line-height, 15px);

				& > ul {
					@include responsive(margin-top, 5px);
				}
			}
		}
	}

	.category-selection {
		@include responsive(width, 320px);
		// @include responsive(height, 672px);
		height: auto;
		@include responsive(padding, 20px);
		@include responsive(grid-row-gap, 20px);

		&-close {
			@include responsive(top, 20px);
			@include responsive(right, 20px);
		}

		&-back {

			&-arrow {
				@include responsive(width, 20px);
				@include responsive(height, 16px);
			}

			&-link  {
				display: none;
			}

		}

		&-title {
			@include responsive(font-size, 20px);
			@include responsive(line-height, 28px);
		}

		&-categories {
			@include responsive(grid-column-gap, 15px);
			@include responsive(grid-row-gap, 10px);
		}

		&-category {
			.category-selection-categories-technics & {
				@include responsive(height, 167.33px);
			}

			.category-selection-categories-decor & {
				@include responsive(height, 151.5px);

				&-mcols-1 {
					@include responsive(height, 151.5px);
				}
			}

			@include responsive(font-size, 16px);
			@include responsive(line-height, 22px);

			&-mcols {
				&-1 {
					width: 100%;				
				}
				&-2 {
					@include responsive(width, 132.5px);
				}
			}
		}

		&-filter {
			@include responsive(width, 280px);
			// @include responsive(max-height, 440px);
			max-height: auto;
			@include responsive(padding, 30px, 20px);

			&-close {
				@include responsive(top, 10px);
				@include responsive(right, 10px);
			}

			& .catalog-filters {
				@include responsive(width, 195px);
				
				&-wrap {
					@include responsive(width, 195px);
					height: auto;
					flex-direction: column;
					margin: 0 auto;
				}

				color: var(--Black, #000);

				& > div {
					display: flex;
					@include responsive(grid-column-gap, 17px);
				}	

				& span {
					cursor: pointer;
				}

				&-chevron {
					display: block;
					@include responsive(width, 5px);
					@include responsive(height, 10px);

					&-opened {
						transform: rotate(90deg);
						transform-origin: center;
					}
				}

				&-arrow {
					@include responsive(width, 28px);
					@include responsive(height, 15px);
					cursor: pointer;
				}

				& ul {
					flex-direction: column;
					position: relative;
					top: 0;
					margin: 0;
					padding: 0;
					list-style: none;
				}

				&-level-1 {
					height: auto;
					@include responsive(padding, 15px, 0);
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
					border-top: 1px solid var(--Black, #000);
					border-bottom: none;

					& > ul {
						@include responsive(margin-top, 10px);
						@include responsive(grid-row-gap, 5px);
					}
				}

				&-level-2 {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
					border-bottom: none;

					& > ul {
						@include responsive(margin-top, 5px);
						@include responsive(grid-row-gap, 5px);
					}
				}

				&-level-3 {
					@include responsive(font-size, 14px);
					@include responsive(line-height, 19px);
					color: #A2A2A2;
				}
			}
		}
	}
}
