@import '../../assets/styles/partial.scss';

.breadcrumbs {
	@include responsive(margin-top, 50px);
	@include responsive(grid-column-gap, 30px);

	&-arrow {
		@include responsive(width, 36px);
		@include responsive(height, 28px);
	}

	&-link {
		color: var(--Black, #000);
		@extend .font-16;
	}
}

.not-found {
	@include responsive(margin-bottom, 126px);

	&-404 {
		color: #000;
		@extend .font-helvetica;
		@include responsive(font-size, 200px);
		@include responsive(line-height, 276px);
	}

	&-error {
		color: var(--Black, #000);
		@extend .font-20;
		text-transform: uppercase;
		@include responsive(margin-bottom, 20px);
	}

	&-text {
		color: #000;
		text-align: center;
		@extend .font-20;
		@include responsive(margin-bottom, 45px);
	}

	&-back {		
		@include responsive(width, 305px);
		@include responsive(height, 45px);
		@include responsive(grid-column-gap, 12px);
		@include responsive(border-radius, 3px);
		border: 2px solid var(--Black, #000);
		background: var(--Black, #000);

		& span {
			color: var(--White, #FFF);
			@extend .font-16;
		}
	}
}

@media screen and (max-width: 576px) {
	.breadcrumbs {
		@include responsive(margin-top, 55px);
		@include responsive(height, 28px);

		&-arrow {
			@include responsive(width, 20px);
			@include responsive(height, 16px);
		}

		&-link {
			display: none;
		}
	}

	.not-found {
		@include responsive(margin-bottom, 117px);

		&-wrap {
			@include responsive(margin-top, 55px);
		}

		&-404 {
			@include responsive(font-size, 150px);
			@include responsive(line-height, 207px);
		}

		&-error, &-text {
			@include responsive(font-size, 20px);
			@include responsive(line-height, 28px);
			@include responsive(margin-bottom, 20px);
		}

		&-back {		
		    @include responsive(width, 320px);
		    @include responsive(height, 30px);
		    @include responsive(grid-column-gap, 10px);
			@include responsive(border-radius, 3px);

			& span {
				@include responsive(font-size, 12px);
				@include responsive(line-height, 17px);
			}
		}
	}
}
