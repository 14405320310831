@import './partial.scss';

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
}

button, .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &[disabled] {
    opacity: 0.7;
    cursor: default;
  }
}

.btn-black {
  border: 1px solid var(--Black, #000) !important;
  background: var(--Black, #000) !important;
  color: var(--White, #FFF) !important;
}

.btn-white {
  border: 1px solid var(--White, #FFF) !important;
  background: var(--White, #FFF) !important;
  color: var(--Black, #000) !important;
}

.btn-xs {
  @include responsive(width, 45px);
  @include responsive(height, 45px);
  @include responsive(border-radius, 3px);
}

.btn-small {
  @include responsive(width, 196px);
  @include responsive(height, 45px);
  @include responsive(grid-column-gap, 4px);
  @include responsive(padding, 0px, 15px);
  @include responsive(border-radius, 3px);
  @extend .font-16;
}

.btn-ms {
  @include responsive(width, 266px);
  @include responsive(height, 45px);
  @include responsive(grid-column-gap, 12px);
  @include responsive(border-radius, 3px);
  @extend .font-16;
}

.btn-medium {
  @include responsive(width, 305px);
  @include responsive(height, 45px);
  @include responsive(grid-column-gap, 12px);
  @include responsive(border-radius, 3px);
  @extend .font-16;
}

.btn-large {
  @include responsive(width, 414px);
  @include responsive(height, 45px);
  @include responsive(grid-column-gap, 15px);
  @include responsive(border-radius, 3px);
  @extend .font-20;
}

.btn-icon {
  &-plus, &-minus {
    @include responsive(width, 24px);
    @include responsive(height, 24px);
  }

  &-arrow {
    @include responsive(width, 28px);
    @include responsive(height, 14px);
  }

  &-pencil {
    @include responsive(width, 24px);
    @include responsive(height, 24px);
  }

  &-settings {
    @include responsive(width, 24px);
    @include responsive(height, 24px);
  }

  &-trash {
    @include responsive(width, 24px);
    @include responsive(height, 25px);
  }
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.flex-1 {
  flex: 1;
}

.container {
  @include responsive(width, 1280px);
  margin: 0 auto;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}
.m-0 {
  margin: 0 !important;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 700;
}

.text-yellow {
  color: var(--yell, #DA932A);
}

.text-red {
  color: var(--Red, #C53939);
}

.text-white {
  color: var(--White, #FFF);
}

.text-green {
  color: #005417;
}

@media screen and (max-width: 576px) {

  .btn-xs {
    @include responsive(width, 30px);
    @include responsive(height, 30px);
    @include responsive(border-radius, 3px);
  }

  .btn-small {
    @include responsive(width, 152px);
    @include responsive(height, 30px);
    @include responsive(grid-column-gap, 4px);
    @include responsive(border-radius, 3px);
    @include responsive(font-size, 12px);
    @include responsive(line-height, 17px);
  }

  .btn-medium {
    @include responsive(width, 220px);
    @include responsive(height, 30px);
    @include responsive(grid-column-gap, 10px);
    @include responsive(border-radius, 3px);
    @include responsive(font-size, 12px);
    @include responsive(line-height, 17px);
  }

  .btn-large {
    @include responsive(width, 320px);
    @include responsive(height, 30px);
    @include responsive(grid-column-gap, 10px);
    @include responsive(border-radius, 3px);
    @include responsive(font-size, 12px);
    @include responsive(line-height, 17px);
  }

  .btn-icon {
    &-plus {
      @include responsive(width, 17px);
      @include responsive(height, 17px);
    }

    &-arrow {
      @include responsive(width, 21px);
      @include responsive(height, 10px);
    }

    &-pencil {
      @include responsive(width, 17px);
      @include responsive(height, 17px);
    }

    &-settings {
      @include responsive(width, 17px);
      @include responsive(height, 17px);
    }

    &-trash {
      @include responsive(width, 15px);
      @include responsive(height, 16px);
    }
  }

  .container {
    @include responsive(width, 320px);
  }

}
